import React, { useState } from 'react';
import { Input } from 'antd';
import { getAuthToken } from 'Helper/AuthTokenHelper';
import { Navigate } from 'react-router-dom';
import Sidebar from 'Components/Common/Sidebar';
import Header from 'Components/Common/Header';

export default function PrivateRouter({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  if (!getAuthToken()) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div
      className={collapsed ? 'main_wrapper sidebar_collapse' : 'main_wrapper'}
    >
      <div className="inner_wrapper">
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="content_wrapper">
          <Header collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className="search_bar_mobile d-none">
            <div className="search_top">
              <Input placeholder="Search anything here" />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
  // }
}
