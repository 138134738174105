import  { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Routes from 'routes/index';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/theme-context';
import { Spin } from 'antd';
import axios from 'axios';
import {
  clearAuthToken,
  getAuthToken,
  saveToken,
} from 'Helper/AuthTokenHelper';
import { toast } from 'react-toastify';
import '../scss/Style.scss';
import { setIsLogin, setIsPageWait } from 'redux/reducers/auths.slice';
import { useDispatch } from 'react-redux';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('error', error);
    const { status, msg } = error?.response?.data || {};
    if (status === 401) {
      clearAuthToken();
      window.location.href = '/lead';
      toast.error('Access Token is not valid or has expired');
    } else if (status === 406 || status === 404) {
      clearAuthToken();
      window.location.href = '/lead';
      toast.error(
        'Your account is deactivated by admin. Please contact your admin.',
      );
    }
    return Promise.reject(error);
  },
);
export function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setIsPageWait(true));
    let UserPreferences = getAuthToken();
    if (UserPreferences) {
      dispatch(setIsLogin(true));
      saveToken(UserPreferences?.token);
    }
    dispatch(setIsPageWait(false));
  }, [dispatch, navigate]);

  const isBrowserDefaultDark = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('default-theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    return localStorageTheme ? localStorageTheme : browserDefault;
  };
  const [theme, setTheme] = useState(getDefaultTheme());
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`}>
        <Suspense
          fallback={
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          }
        >
           <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management</title>
            <meta name="description" content="A Nimaaya Lead Management application" />
        </Helmet>
          <Routes />
        </Suspense>
      </div>
    </ThemeContext.Provider>
  );
}
