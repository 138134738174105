import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import LogoIcon from '../../Img/logo-icon.svg';
import LogoText from '../../Img/logo-text.svg';
import LoginBg from '../../Img/login-main.webp';
import { Button, Checkbox, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import DarkIcon from '../../Img/dark-btn.svg';
import LightIcon from '../../Img/light-btn.svg';
import { ThemeContext } from '../../contexts/theme-context';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from 'redux/reducers/auths.slice';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { getAuthToken } from 'Helper/AuthTokenHelper';
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getcurrentYear = new Date().getFullYear();
  const [form] = Form.useForm();
  const { theme, setTheme } = useContext(ThemeContext);
  const [rememberMe, setRememberMe] = useState(
    JSON.parse(localStorage.getItem('RememberMe')) || false,
  );
  const { isUserLogin } = useSelector(({ auth }) => auth);
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  const onFinish = values => {
    if (rememberMe) {
      localStorage.setItem('RememberMe', JSON.stringify(true));
      const encryptedUserEmail = CryptoJS.AES.encrypt(
        values.user_email,
        'secret_key',
      ).toString();
      const encryptedPassword = CryptoJS.AES.encrypt(
        values.password,
        'secret_key',
      ).toString();

      Cookies.set('rememberedUserEmail', encryptedUserEmail);
      Cookies.set('rememberedPassword', encryptedPassword);
    } else {
      localStorage.setItem('RememberMe', JSON.stringify(false));
      Cookies.remove('rememberedUserEmail');
      Cookies.remove('rememberedPassword');
    }
    dispatch(loginAction(values));
  };
  const validatePassword = (rule, value) => {
    if (!value) return Promise.reject('Please input your password!');
    if (value.length < 8)
      return Promise.reject('Password must be at least 8 characters long');
    if (!/\d/.test(value))
      return Promise.reject('Password must contain at least one digit');
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value))
      return Promise.reject(
        'Password must contain at least one special character',
      );
    return Promise.resolve();
  };
  const isLoginValidate = useCallback(
    async isUserLogin => {
      const loggedUserData = getAuthToken();
      if (isUserLogin && loggedUserData) {
        navigate('/dashboard');
      } else {
        navigate('/');
      }
    },
    [navigate],
  );
  useEffect(() => {
    isLoginValidate(isUserLogin);
  }, [isUserLogin, isLoginValidate]);
  
  useEffect(() => {
    const rememberedUserEmail = Cookies.get('rememberedUserEmail');
    const rememberedPassword = Cookies.get('rememberedPassword');

    if (rememberedUserEmail && rememberedPassword) {
      const decryptedUserEmail = CryptoJS.AES.decrypt(
        rememberedUserEmail,
        'secret_key',
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        rememberedPassword,
        'secret_key',
      ).toString(CryptoJS.enc.Utf8);

      form.setFieldsValue({
        user_email: decryptedUserEmail,
        password: decryptedPassword,
      });
    }
  }, [form]);
  return (
    <div className="login_wrapper">
       <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management</title>
            <meta name="description" content="A Nimaaya Lead Management application" />
        </Helmet>
      <Row className="g-0">
        <Col lg={6}>
          <div className="login_form_wrap">
            <div className="login_form_Inner">
              <div className="login_top_wrapper">
                <div className="logo">
                  <img src={LogoIcon} className="logo_icon" alt="" />
                  <img src={LogoText} className="logo_text" alt="" />
                </div>
                <h1>👋🏻 Hello! Welcome Back</h1>
              </div>
              <div className="login_form_box">
                <Form
                  form={form}
                  name="basic"
                  initialValues={{ remember: false }}
                  layout="vertical"
                  onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="user_email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                        transform: value => value.trim(),
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    className="mb-2"
                    rules={[
                      {
                        validator: validatePassword,
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter a password" />
                  </Form.Item>

                  <Row className="align-items-center mb-5">
                    <Col>
                      <Form.Item className="m-0">
                        <Checkbox
                          name="rememberMe"
                          checked={rememberMe}
                          onChange={e => {
                            setRememberMe(!rememberMe);
                          }}
                        >
                          Remember me
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <div className="text-end">
                        <Link to="/forgot-password" className="text_light">
                          Forgot Password?
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button htmlType="login" className="w-100 btn_primary">
                      Login
                    </Button>
                  </Form.Item>

                  {/* <p className="text-center text_dark">
                  Don’t have an account?
                  <Link to="/signup" className="ps-1">
                    Create an Account
                  </Link>
                </p> */}
                </Form>
              </div>
            </div>
            <div className="copyright_wrap d-none d-lg-block">
              <p className="m-0 text-center">
                @{getcurrentYear && getcurrentYear} Nimaaya IVF Hospital. All
                rights reserved.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="login_img d-none d-lg-block">
            <img src={LoginBg} alt="" width={880} height={865} />
          </div>
        </Col>
      </Row>
      <div className="login_display_btn">
        <Button
          type="button"
          className="btn_transparent"
          onClick={handleThemeChange}
        >
          {theme === 'light' ? (
            <img src={DarkIcon} className="dark_icon" alt="" />
          ) : (
            <img src={LightIcon} className="light_icon" alt="" />
          )}
        </Button>
      </div>
    </div>
  );
}
