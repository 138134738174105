import React, { useContext } from 'react';
import { Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import ToggleMenu from '../../Img/burger_menu.svg';
import DarkIcon from '../../Img/dark-btn.svg';
import LightIcon from '../../Img/light-btn.svg';
import SelectArrow from '../../Img/select-arrow.svg';
import LogoIcon from '../../Img/logo-icon.svg';
import LogoText from '../../Img/logo-text.svg';
import { Dropdown } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../contexts/theme-context';
import { setIsLogin } from 'redux/reducers/auths.slice';
import { useDispatch } from 'react-redux';
import { clearAuthToken, getAuthToken } from 'Helper/AuthTokenHelper';

export default function Header({ collapsed, setCollapsed, props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const leadManagement = ['/add-lead', '/edit-lead', '/lead', '/'];
  const userManagement = ['/user-management', '/add-user', '/edit-user'];
  const leadReport = ['/lead-report'];
  const UserPreferences = getAuthToken();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  const userDropDown = [
    {
      key: 'Logout',
      label: (
        <div
          onClick={() => {
            dispatch(setIsLogin(false));
            clearAuthToken();
            navigate('/');
          }}
        >
          Logout
        </div>
      ),
    },
  ];
  return (
    <header>
      <Row className="align-items-center">
        <Col xs={2} md={7}>
          <div className="hedaer_left">
            <ul>
              <li>
                <div className="sidebar_logo header_left_logo">
                  <div className="Logo_icon">
                    <img src={LogoIcon} alt="" />
                  </div>
                  <div className="Logo_text ps-2">
                    <img src={LogoText} alt="" />
                  </div>
                </div>
              </li>
              <li>
                <Button className="toggle_btn" onClick={toggleCollapsed}>
                  <img src={ToggleMenu} alt="" />
                </Button>
              </li>
              <li className="header_page_title d-none d-md-block">
                <h2 className="m-0">
                  {leadManagement.includes(pathname)
                    ? 'Lead'
                    : userManagement.includes(pathname)
                      ? 'User Management'
                      : leadReport.includes(pathname)
                        ? 'Lead'
                        : ''}
                </h2>
              </li>
            </ul>
          </div>
        </Col>
        <Col className="d-block d-md-none" xs={8}>
          <div className="page_heading">
            <img src={LogoIcon} alt="" />
            <h1>
              {leadManagement.includes(pathname)
                ? 'Lead'
                : userManagement.includes(pathname)
                  ? 'User Management'
                  : leadReport.includes(pathname)
                    ? 'Lead'
                    : ''}
            </h1>
          </div>
        </Col>
        <Col xs={2} md={5}>
          <div className="right_header">
            <ul>
              <li className="d-none d-md-block">
                <Button
                  type="button"
                  className="btn_transparent"
                  onClick={handleThemeChange}
                >
                  {theme === 'light' ? (
                    <img src={DarkIcon} className="dark_icon" alt="" />
                  ) : (
                    <img src={LightIcon} className="light_icon" alt="" />
                  )}
                </Button>
              </li>
              <li className="d-block">
                <Dropdown
                  menu={{
                    items: userDropDown,
                  }}
                  placement="bottomLeft"
                  className="user_dropdown"
                  trigger={['click']}
                >
                  <Button>
                    <span className="user_img me-md-2">
                      <div
                        className="user_inner_text"
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '24px',
                        }}
                      >
                        {UserPreferences?.user_name
                          ? `${UserPreferences?.user_name
                              ?.charAt(0)
                              ?.toUpperCase()}`
                          : 'UN'}
                      </div>
                    </span>
                    <span className="d-md-inline-block d-none">
                      {UserPreferences?.user_name}
                    </span>
                    <img
                      src={SelectArrow}
                      className="ms-2 down_arrow d-none d-md-inline-block"
                      alt=""
                    />
                  </Button>
                </Dropdown>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </header>
  );
}
