export const dateFormat = 'YYYY-MM-DD';
export const dateFormatForDispalay = 'DD-MM-YYYY';

export const searchLocationField = [
  {
    label: 'All Location',
    value: '',
  },
  {
    label: 'Surat-Nimaaya',
    value: 'Surat-Nimaaya',
  },
  {
    label: 'Surat-Station',
    value: 'Surat-Station',
  },
  {
    label: 'Vadodara',
    value: 'Vadodara',
  },
  {
    label: 'Ahmedabad',
    value: 'Ahmedabad',
  },
];
export const locationField = [
  {
    label: 'Surat-Nimaaya',
    value: 'Surat-Nimaaya',
  },
  {
    label: 'Surat-Station',
    value: 'Surat-Station',
  },
  {
    label: 'Vadodara',
    value: 'Vadodara',
  },
  {
    label: 'Ahmedabad',
    value: 'Ahmedabad',
  },
];
export const docterList = [
  {
    label: 'Dr Pooja Nadkarni Singh',
    value: 'Dr Pooja Nadkarni Singh',
  },
  {
    label: 'Dr Yuvrajsingh Jadeja',
    value: 'Dr Yuvrajsingh Jadeja',
  },
  {
    label: 'Dr Birva Dave',
    value: 'Dr Birva Dave',
  },
  {
    label: 'Dr Harshil Shah',
    value: 'Dr Harshil Shah',
  },
  {
    label: 'Dr Shubhra Tripathi',
    value: 'Dr Shubhra Tripathi',
  },
  {
    label: 'Dr Santosh Yadav',
    value: 'Dr Santosh Yadav',
  },
  {
    label: 'Dr Akshay Nadkarni',
    value: 'Dr Akshay Nadkarni',
  },
  {
    label: 'Dr Parth Soni',
    value: 'Dr Parth Soni',
  },
  {
    label: 'Dr Jayna Unadkat',
    value: 'Dr Jayna Unadkat',
  },
  {
    label: 'Dr Neena Pillai',
    value: 'Dr Neena Pillai',
  },
  {
    label: 'Dr Khushbu Tankshali',
    value: 'Dr Khushbu Tankshali',
  },
];

export const typeOfPatient = [
  {
    label: 'Married Couple',
    value: 'Married Couple',
  },
  {
    label: 'Unmarried Couple',
    value: 'Unmarried Couple',
  },
  {
    label: 'Single Women',
    value: 'Single Women',
  },
  {
    label: 'Donor (Male)',
    value: 'Donor (Male)',
  },
  {
    label: 'Donor (Female)',
    value: 'Donor (Female)',
  },
  {
    label: 'Surrogate Mother',
    value: 'Surrogate Mother',
  },
];
