import * as React from 'react';
import ReactDOM from 'react-dom/client';
import 'sanitize.css/sanitize.css';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { configureAppStore } from 'redux/configureStore';
import 'react-toastify/dist/ReactToastify.css';
const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <HelmetProvider>
        <App />
        <ToastContainer />
      </HelmetProvider>
    </Provider>
  </BrowserRouter>,
);
