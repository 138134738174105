import { combineReducers } from '@reduxjs/toolkit';

import auth from './reducers/auths.slice';
import lead from './reducers/LeadManagement/lead.slice';
import userData from './reducers/UserManagement/user.slice'

export function createReducer(injectedReducers) {
  return combineReducers({
    ...injectedReducers,
    auth,
    lead,
    userData,
  });
}
