import ForgotPassword from 'Components/Auth/ForgotPassword';
import LinkExpired from 'Components/Auth/LinkExpired';
import Login from 'Components/Auth/Login';
import PasswordReset from 'Components/Auth/PasswordReset';
import ResetPassword from 'Components/Auth/ResetPassword';
import SendCode from 'Components/Auth/SendCode';
import SetPassword from 'Components/Auth/SetPassword';
import AddLead from 'Components/Pages/LeadManagement/AddLead';
import AllLead from 'Components/Pages/LeadManagement/AllLead';
import Dashboard from 'Components/Pages/LeadManagement/Dashboard';
import PageNotFound from 'Components/Pages/PageNotFound';
import AddUser from 'Components/Pages/UserRollManagement/AddUser';
import AllUsers from 'Components/Pages/UserRollManagement/AllUsers';
export const commonRoute = [
  {
    path: '/',
    element: <Login />,
    isPublic: true,
  },
  {
    path: '/lead',
    element: <AddLead />,
    isPublic: false,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    isPublic: false,
  },
  {
    path: '/edit-lead',
    element: <AddLead />,
    isPublic: false,
  },
  {
    path: '/lead-report',
    element: <AllLead />,
    isPublic: false,
  },
  {
    path: '/link-expired',
    element: <LinkExpired />,
    isPublic: true,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    isPublic: true,
  },
  {
    path: '/password-reset',
    element: <PasswordReset />,
    isPublic: true,
  },
  {
    path: '/reset-password',
    element: <SetPassword />,
    isPublic: true,
  },
  {
    path: '/set-password/:token',
    element: <ResetPassword />,
    // isPublic: true,
  },
  {
    path: '/send-code',
    element: <SendCode />,
    isPublic: true,
  },

  {
    path: '*',
    element: <PageNotFound />,
    // isPublic: false,
  },
];
export const adminRoute = [
  {
    path: '/user-management',
    element: <AllUsers />,
    isPublic: false,
  },
  {
    path: '/lead-report',
    element: <AllLead />,
    isPublic: false,
  },
  {
    path: '/add-user',
    element: <AddUser />,
    isPublic: false,
  },
  {
    path: '/edit-user',
    element: <AddUser />,
    isPublic: false,
  },
];
export const userRoute = [
  {
    path: '/add-user',
    element: <AddUser />,
    isPublic: false,
  },
  {
    path: '/edit-user',
    element: <AddUser />,
    isPublic: false,
  },
  {
    path: '/lead-report',
    element: <AllLead />,
    isPublic: false,
  },
];
