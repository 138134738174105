import NotFoundImg from '../../Img/404.png';
import { Helmet } from 'react-helmet-async';
const PageNotFound = () => {
  return (
    <div className="not_found">
      <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management - Page Not Found</title>
            <meta name="description" content="A Nimaaya Lead Management - Page Not Found" />
        </Helmet>
      <img src={NotFoundImg} alt="NotFoundImg" />
      <h2>404 - Not Found</h2>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
};
export default PageNotFound;
