import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import LogoIcon from '../../Img/logo-icon.svg';
import LogoText from '../../Img/logo-text.svg';
import LeftArrow from '../../Img/left-arrow.svg';
import ResetBg from '../../Img/reset-bg.jpg';
import PassResetImg from '../../Img/password-reset-img.png';
import BackArrow from '../../Img/back-arrow.svg';
import { Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetViaEmail } from 'redux/reducers/auths.slice';

export default function SendCode() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isType = localStorage.getItem('Forgot');
  const onFinish = useCallback(
    async values => {
      await dispatch(resetViaEmail(values)).then(data => {
        if (data?.payload) {
          navigate('/password-reset', { state: values });
          localStorage.setItem('emailorpno', JSON.stringify(values));
        }
      });
    },
    [dispatch, navigate],
  );
  return (
    <div className="login_wrapper">
      <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management - Send Code</title>
            <meta name="description" content="A Nimaaya Lead Management - Send Code" />
        </Helmet>
      <Row className="g-0">
        <Col lg={6}>
          <div className="login_form_wrap">
            <div className="login_form_Inner">
              <div className="login_top_wrapper">
                <div className="logo  d-none d-lg-flex">
                  <img src={LogoIcon} className="logo_icon" alt="" />
                  <img src={LogoText} className="logo_text" alt="" />
                </div>
                <h1 className="mb-3">
                  {isType === 'isEmail' ? 'Email Address' : 'Phone Number'}
                </h1>
                <p className="text-center mb-4">
                  Enter your{' '}
                  {isType === 'isEmail' ? ' email address' : 'phone number'} to
                  receive code
                </p>
                <img
                  src={PassResetImg}
                  className="top_img d-block d-lg-none"
                  alt=""
                />
                <Link
                  to="/forgot-password"
                  className="back_arrow d-block d-lg-none"
                >
                  <img src={BackArrow} alt="" className="me-2" />
                </Link>
              </div>

              <div className="forgot_password_wrapper">
                <Form
                  onFinish={onFinish}
                  //   onFinishFailed={onFinishFailed}
                  style={{ height: 'auto', padding: 0 }}
                >
                  <Form.Item
                    label=""
                    name="user_email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Button htmlType="login" className="w-100 btn_primary mb-4">
                    Continue
                  </Button>
                </Form>
                <div className="back_to_login text-center">
                  <Link to="/" className="text_secondary d-inline-flex">
                    <img src={LeftArrow} alt="" className="me-2" /> Back to
                    login
                  </Link>
                </div>
              </div>
            </div>
            <div className="copyright_wrap d-none d-lg-block">
              <p className="m-0 text-center">
                @2023 Nimaaya IVF Hospital. All rights reserved.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={6} className="d-none d-lg-block">
          <div className="forgot_bg">
            <img src={ResetBg} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
