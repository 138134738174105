import axios from 'axios';

export const getAuthToken = () => {
  const sessionData = localStorage.getItem('User');
  if (sessionData) {
    return JSON.parse(window.atob(sessionData));
  } else {
    return null;
  }
};

export const setAuthToken = user_data => {
  const settings = {
    ...user_data,
  };
  saveToken(user_data?.token);
  localStorage.setItem('User', window.btoa(JSON.stringify(settings)));
};

export const setAuthTempToken = user_data => {
  const settings = {
    ...user_data,
  };
  saveToken(user_data?.token);
  localStorage.setItem('User', window.btoa(JSON.stringify(settings)));
};

export const saveToken = access_Token => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ` + access_Token;
  } catch (e) {
    console.log('Error while setup token', e);
  }
};

export const clearAuthToken = () => {
  localStorage.removeItem('User');
  localStorage.removeItem('userLocation');
  clearToken();
};

const clearToken = () => {
  delete axios.defaults.headers.common['Authorization'];
};
