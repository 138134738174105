import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import PrivateRouter from './PrivateRouter';
import { adminRoute, commonRoute, userRoute } from './appRoute';
import { useMemo } from 'react';
import { getAuthToken } from 'Helper/AuthTokenHelper';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import PageNotFound from 'Components/Pages/PageNotFound';

export default function Index() {
  const UserPreferences = getAuthToken();
  const { isPageWait } = useSelector(({ auth }) => auth);
  const AppRoute = useMemo(() => {
    return UserPreferences?.roll_type === 'user'
      ? [...commonRoute, ...userRoute]
      : UserPreferences?.roll_type === 'admin'
        ? [...commonRoute, ...adminRoute]
        : [...commonRoute];
  }, [UserPreferences?.roll_type]);
  return !isPageWait ? (
    <Routes>
      {AppRoute?.map((item, index) => {
        return item?.isPublic === false ? (
          <Route
            key={index}
            path={item?.path}
            element={<PrivateRouter>{item.element}</PrivateRouter>}
          />
        ) : item.path === '*' ? (
          <Route key={index} path="*" element={<PageNotFound />} />
        ) : (
          <Route key={index} path={item?.path} element={item.element} />
        );
      })}
    </Routes>
  ) : (
    <Spin tip="Loading" size="large">
      <div className="content" />
    </Spin>
  );
}
