import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Modal,
  Pagination,
  Spin,
  Table,
  Form,
  Input,
  Select,
  DatePicker,
  Dropdown,
  Space,
  Menu,
  Popover,
  Popconfirm,
  message,
} from 'antd';
import { Col, Row } from 'react-bootstrap';
import TrashIcon from '../../../Img/trash.svg';
import EditIcon from '../../../Img/edit.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  CloseCircleOutlined,
  ExportOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import {
  deleteLeadData,
  getLeadData,
  setCurrentPage,
  setLeadCreated,
  exportLeadData,
  setPageSize,
} from 'redux/reducers/LeadManagement/lead.slice';
import moment from 'moment';
import { useNavigate } from 'react-router';
import {
  dateFormat,
  searchLocationField,
  typeOfPatient,
  dateFormatForDispalay,
  docterList,
} from './Data';
import { getAuthToken } from 'Helper/AuthTokenHelper';

const searchDataInitialState = {
  search: '',
  location_filter: null,
  dr_filter: null,
  patient_type_filter: null,
  start_date: null,
  end_date: null,
};
const exportOption = [
  { value: 'All', label: 'All' },
  { value: '15', label: '15' },
  { value: '100', label: '100' },
];
const AllLead = ({ setIsAddLead, setSelectedLeadId }) => {
  const {
    currentPage,
    totalPages,
    pageSize,
    leadList,
    isLeadCreated,
    leadLoading,
    count,
  } = useSelector(({ lead }) => lead);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  // const NewList = Object.assign([], leadList, { count });
  // console.log('NewList', NewList);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [userId, setUserId] = useState();
  const [searchData, setSearchData] = useState(searchDataInitialState);
  const handlePageSizeChange = (current, size) => {
    dispatch(setCurrentPage(current));
    dispatch(setPageSize(size));
  };

  // const showModal = data => {
  //   setIsModalOpen(true);
  //   setUserId(data?._id);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const handelDelete = async (id) => {
    if (id) {
      await dispatch(deleteLeadData({ lead_id: id }));
      await dispatch(
        getLeadData({ start: currentPage, limit: pageSize, searchData }),
      );
    }
  };

  const handleSearchInput = (e, startPage, searchData, isSearch) => {
    let newSearcdata = searchData;
    if (isSearch) {
      newSearcdata = { ...searchData, search: e?.target?.value || '' };
    }
    dispatch(
      getLeadData({
        start: currentPage,
        limit: pageSize,
        searchData: newSearcdata,
      }),
    );
  };

  const debouncehandleSearchInput = useCallback(
    _.debounce(handleSearchInput, 800),
    [],
  );

  const handleDateChange = useCallback(
    dates => {
      const [startDate, endDate] = dates || [];
      setSearchData({
        ...searchData,
        start_date: startDate ? startDate.format(dateFormat) : '',
        end_date: endDate ? endDate.format(dateFormat) : '',
      });

      debouncehandleSearchInput(startDate, currentPage, {
        ...searchData,
        start_date: startDate ? startDate.format(dateFormat) : '',
        end_date: endDate ? endDate.format(dateFormat) : '',
      });
    },
    [debouncehandleSearchInput, searchData, currentPage],
  );

  const handlePageChange = useCallback(
    page => {
      dispatch(setCurrentPage(page));
    },
    [dispatch],
  );

  const getLeadDataList = useCallback(
    isCler => {
      dispatch(
        getLeadData({
          start: currentPage,
          limit: pageSize,
          searchData: isCler ? searchDataInitialState : searchData,
        }),
      );
    },
    [currentPage, dispatch, pageSize, searchData],
  );
  useEffect(() => {
    dispatch(
      getLeadData({
        start: currentPage,
        limit: pageSize,
        searchData,
      }),
    );
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    if (isLeadCreated) {
      dispatch(
        getLeadData({
          start: currentPage,
          limit: pageSize,
          searchData,
        }),
      );
    }
    dispatch(setLeadCreated(false));
  }, [isLeadCreated, dispatch, currentPage]);

  const cancel = e => {
  };

  const columns = [
    {
      title: 'Sr No',
      key: 'sno',
      render: (text, data, index) =>
        data?.patient_name ? index + 1 : 'Total : ',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, data) =>
        data?.patient_name ? (
          <div className="action_wrap">
            <ul className="d-flex align-items-center user_roll">
              <li className="btn_delete btn_edit">
                <Button
                  className="btn_transparent"
                  onClick={() =>
                    navigate('/edit-lead', { state: { leadId: data?._id } })
                  }
                >
                  <img src={EditIcon} alt="" />
                </Button>
              </li>
              <li className="btn_delete">
                {/* <Button
                  className="btn_transparent"
                  onClick={() => showModal(data)}
                >
                  <img src={TrashIcon} alt="" />
                </Button> */}
                <Popconfirm
                  title="Delete Lead"
                  description=" Are you sure you want to delete this lead?"
                  onConfirm={()=>{handelDelete(data?._id)}}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="btn_transparent">
                    <img src={TrashIcon} alt="" />
                  </Button>
                </Popconfirm>
              </li>
            </ul>
          </div>
        ) : (
          <>{data?.action || 0}</>
        ),
    },
    {
      title: 'Full Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
      render: (text, row) => (
        <span>{row?.patient_name ? row?.patient_name : '-'}</span>
      ),
    },
    {
      title: 'DOB',
      dataIndex: 'patient_dob',
      key: 'patient_dob',
      render: (text, row) =>
        row?.patient_dob ? moment(text).format(dateFormatForDispalay) : '-',
    },
    {
      title: 'Gender',
      dataIndex: 'patient_gender',
      key: 'patient_gender',
    },
    {
      title: 'Date of Consultation',
      dataIndex: 'date_of_consultation',
      key: 'date_of_consultation',
      render: (text, row) =>
        row?.date_of_consultation
          ? moment(text).format(dateFormatForDispalay)
          : '-',
      className: 'date_column',
    },
    {
      title: 'Type of Consultation',
      dataIndex: 'type_of_consultation',
      key: 'type_of_consultation',
      render: (text, row) =>
      row?.type_of_consultation
        ? row?.type_of_consultation
        : '-',
      className: 'date_column',
    },
    {
      title: 'Status',
      dataIndex: 'customer_is_open',
      key: 'customer_is_open',
      render: (text, row) => (
        <span
          className={
            row?.customer_is_open
              ? 'bedge bedge_active'
              : row?.customer_is_open === false
                ? 'bedge bedge_inactive'
                : ''
          }
        >
          {row?.customer_is_open
            ? 'Open'
            : row?.customer_is_open === false
              ? 'Close'
              : '-'}
        </span>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'patient_phone_number',
      key: 'patient_phone_number',
      render: (text, row) => (
        <span>
          {row?.patient_phone_number ? row?.patient_phone_number : '-'}
        </span>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: locationset => {
        return (
        <div className="concat_text">
           {locationset?.length > 0 ? locationset?.join(', ') : "-"}
       </div>
      )},
    },
    {
      title: 'Reference Type',
      dataIndex: 'reference_type',
      key: 'reference_type',
      render: (text, row) => (
        <span>{row?.reference_type ? row?.reference_type : '-'}</span>
      ),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      render: (text, row) => (row?.reference ? row?.reference : '-'),
    },

    {
      title: 'Pincode',
      dataIndex: 'pincode',
      key: 'pincode',
      render: (text, row) => (row?.pincode ? row?.pincode : '-'),
    },
    {
      title: 'Address-1',
      dataIndex: 'address1',
      key: 'address1',
      render: (text, row) => (row?.address1 ? row?.address1 : '-'),
    },
    {
      title: 'Address-2',
      dataIndex: 'address2',
      key: 'address2',
      render: (text, row) => (row?.address2 ? row?.address2 : '-'),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: (text, row) => (row?.city ? row?.city : '-'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text, row) => (row?.state ? row?.state : '-'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text, row) => (row?.country ? row?.country : '-'),
    },

    {
      title: 'Doctor Name',
      dataIndex: 'doctor_name',
      key: 'doctor_name',
      render: (text, row) => (row?.doctor_name ? row?.doctor_name : '-'),
    },
    {
      title: 'Type Of Patient',
      dataIndex: 'type_of_patient',
      key: 'type_of_patient',
      render: (text, row) => (
        <span>{row?.type_of_patient ? row?.type_of_patient : '-'}</span>
      ),
    },
    {
      title: 'IO Female',
      dataIndex: 'investigation_of_female',
      key: 'investigation_of_female',
      render: (text, row) =>
        row?.investigation_of_female === true
          ? 'Yes'
          : row?.investigation_of_female === false
            ? 'No'
            : row?.investigation_of_female,
    },
    {
      title: 'IO Female Date',
      dataIndex: 'investigation_of_female_date',
      key: 'investigation_of_female_date',
      render: (text, row) =>
        row?.investigation_of_female_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'IO Female Loc',
      dataIndex: 'investigation_of_female_location',
      key: 'investigation_of_female_location',
      render: (text, row) =>
        row?.investigation_of_female_location
          ? row?.investigation_of_female_location
          : '-',
    },
    {
      title: 'IO Male',
      dataIndex: 'investigation_of_male',
      key: 'investigation_of_male',
      render: (text, row) =>
        row?.investigation_of_male === true
          ? 'Yes'
          : row?.investigation_of_male === false
            ? 'No'
            : row?.investigation_of_male,
    },
    {
      title: 'IO Male Date',
      dataIndex: 'investigation_of_male_date',
      key: 'investigation_of_male_date',
      render: (text, row) =>
        row?.investigation_of_male_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'IO Male Loc',
      dataIndex: 'investigation_of_male_location',
      key: 'investigation_of_male_location',
      render: (text, row) =>
        row?.investigation_of_male_location
          ? row?.investigation_of_male_location
          : '-',
    },
    {
      title: 'Semen Analysis',
      dataIndex: 'semen_analysis',
      key: 'semen_analysis',
      render: (text, row) =>
        row?.semen_analysis === true
          ? 'Yes'
          : row?.semen_analysis === false
            ? 'No'
            : row?.semen_analysis,
    },
    {
      title: 'Semen Analysis Date',
      dataIndex: 'semen_analysis_date',
      key: 'semen_analysis_date',
      render: (text, row) =>
        row?.semen_analysis_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'Semen Analysis Loc',
      dataIndex: 'semen_analysis_location',
      key: 'semen_analysis_location',
      render: (text, row) =>
        row?.semen_analysis_location ? row?.semen_analysis_location : '-',
    },
    {
      title: 'Hysteroscopy',
      dataIndex: 'hysteroscopy',
      key: 'hysteroscopy',
      render: (text, row, record) =>
        row?.hysteroscopy === true
          ? 'Yes'
          : row?.hysteroscopy === false
            ? 'No'
            : row?.hysteroscopy,
    },
    {
      title: 'Hysteroscopy Date',
      dataIndex: 'hysteroscopy_date',
      key: 'hysteroscopy_date',
      render: (text, row) =>
        row?.hysteroscopy_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'Hysteroscopy Loc',
      dataIndex: 'hysteroscopy_location',
      key: 'hysteroscopy_location',
      render: (text, row) =>
        row?.hysteroscopy_location ? row?.hysteroscopy_location : '-',
    },
    {
      title: 'Treatment',
      dataIndex: 'type_of_treatment',
      key: 'type_of_treatment',
      render: (text, row) =>
        row?.type_of_treatment ? row?.type_of_treatment : '-',
    },
    {
      title: 'Treatment Date',
      dataIndex: 'type_of_treatment_date',
      key: 'type_of_treatment_date',
      render: (text, row) =>
        row?.type_of_treatment_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'Treatment Loc',
      dataIndex: 'type_of_treatment_location',
      key: 'type_of_treatment_location',
      render: (text, row) =>
        row?.type_of_treatment_location ? row?.type_of_treatment_location : '-',
    },
    {
      title: 'Stimulation Str',
      dataIndex: 'stimulation_started',
      key: 'stimulation_started',
      render: (text, row) =>
        row?.stimulation_started === true
          ? 'Yes'
          : row?.stimulation_started === false
            ? 'No'
            : row?.stimulation_started,
    },
    {
      title: 'Stimulation Str Date',
      dataIndex: 'stimulation_started_date',
      key: 'stimulation_started_date',
      render: (text, row) =>
        row?.stimulation_started_date
          ? moment(text).format(dateFormatForDispalay)
          : '-',
    },
    {
      title: 'Stimulation Str Loc',
      dataIndex: 'stimulation_started_location',
      key: 'stimulation_started_location',
      render: (text, row) =>
        row?.stimulation_started_location
          ? row?.stimulation_started_location
          : '-',
    },
    {
      title: 'Opu',
      dataIndex: 'opu',
      key: 'opu',
      render: (text, row) =>
        row?.opu === true ? 'Yes' : row?.opu === false ? 'No' : row?.opu,
    },

    {
      title: 'Opu Date',
      dataIndex: 'opu_date',
      key: 'opu_date',
      render: (text, row) =>
        row?.opu_date ? moment(text).format(dateFormatForDispalay) : '-',
    },
    {
      title: 'Opu Loc',
      dataIndex: 'opu_location',
      key: 'opu_location',
      render: (text, row) => (row?.opu_location ? row?.opu_location : '-'),
    },
    {
      title: 'Outcome',
      dataIndex: 'outcome',
      key: 'outcome',
      render: (text, row) => (row?.outcome ? row?.outcome : '-'),
    },

    {
      title: 'Outcome Date',
      dataIndex: 'opu_date',
      key: 'opu_date',
      render: (text, row) =>
        row?.opu_date ? moment(text).format(dateFormatForDispalay) : '-',
    },
    {
      title: 'Outcome Loc',
      dataIndex: 'outcome_location',
      key: 'outcome_location',
      render: (text, row) =>
        row?.outcome_location ? row?.outcome_location : '-',
    },
    {
      title: 'Package',
      dataIndex: 'package_id',
      key: 'package_id',
      render: (text, row) => (row?.package_id ? row?.package_id : '-'),
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      render: (text, row) => (row?.fees ? row?.fees : '-'),
    },
  ];

  const menuProps = (
    <Menu>
      {exportOption.map(item => {
        return (
          <Menu.Item
            key={item?.value}
            onClick={() => {
              Object.keys(count)?.length > 0 && exportLead(item?.value);
            }}
          >
            {item?.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const exportLead = useCallback(
    total => {
      dispatch(
        exportLeadData(
          total === 'All'
            ? {
                ...searchData,
                excel: true,
              }
            : {
                ...searchData,
                excel: true,
                excel_filter: total,
              },
        ),
      );
    },
    [dispatch, searchData],
  );

  return (
    <>
      <Helmet defaultTitle="Nimaaya Lead Management">
        <title>Nimaaya Lead Management - Lead Report</title>
        <meta
          name="description"
          content="A Nimaaya Lead Management - Lead Report"
        />
      </Helmet>
      {leadLoading && (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      )}
      <div className="page_main_content all_lead_wrap">
        <div className="page_inner_content">
          <div className="page_header">
            {/* <Row className="align-items-center justify-content-end"> */}
            <Row className="align-items-center">
              {/* <Col xxl={2}>
                <h3>Lead</h3>
              </Col> */}
              <Col xxl={12}>
                <div className="page_header_right">
                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    autoComplete="off"
                  >
                    <ul className="justify-content-start">
                      {getAuthToken()?.roll_type === 'admin' && (
                        <li className="w_200 w_xxs_100">
                          <Form.Item
                            label=""
                            name="dr_filter"
                            className="custom_select mb-0"
                          >
                            <Select
                              placeholder="Select Doctor"
                              name="dr_filter"
                              value={searchData?.dr_filter}
                              onChange={value => {
                                debouncehandleSearchInput(value, currentPage, {
                                  ...searchData,
                                  dr_filter: value,
                                });
                                setSearchData({
                                  ...searchData,
                                  dr_filter: value,
                                });
                              }}
                              options={[
                                {
                                  label: 'All Docter',
                                  value: '',
                                },
                                ...docterList,
                              ]}
                            />
                          </Form.Item>
                        </li>
                      )}
                      <li className="w_200 w_xxs_100">
                        <Form.Item
                          label=""
                          name="patient_type_filter"
                          className="custom_select mb-0"
                        >
                          <Select
                            placeholder="Type Of Patient"
                            name="patient_type_filter"
                            value={searchData?.patient_type_filter}
                            onChange={value => {
                              debouncehandleSearchInput(value, currentPage, {
                                ...searchData,
                                patient_type_filter: value,
                              });
                              setSearchData({
                                ...searchData,
                                patient_type_filter: value,
                              });
                            }}
                            options={[
                              { label: 'All Patients', value: '' },
                              ...typeOfPatient,
                            ]}
                          />
                        </Form.Item>
                      </li>
                      {/* temporary comment ask to md sir start*/}
                      {/* <li>
                      <Form.Item className="m-0">
                        <RangePicker
                          allowClear={true}
                          format={{
                            format: 'DD-MM-YYYY',
                            type: 'mask',
                          }}
                          size="small"
                          showTime
                          picker="date"
                          value={
                            searchData?.start_date
                              ? [
                                  moment(searchData?.start_date, dateFormat),
                                  moment(searchData?.end_date, dateFormat),
                                ]
                              : null
                          }
                          onChange={handleDateChange}
                        />
                      </Form.Item>
                    </li> */}
                      {/* temporary comment ask to md sir start*/}
                      <li className="search_wrap w_200 w_xxs_100">
                        <div className="search_top">
                          <Input
                            placeholder="Search anything here"
                            name="search"
                            value={searchData?.search}
                            onChange={e => {
                              debouncehandleSearchInput(
                                e,
                                currentPage,
                                searchData,
                                {
                                  ...searchData,
                                  search: e.target.value,
                                },
                                true,
                              );
                              setSearchData({
                                ...searchData,
                                search: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </li>
                      {getAuthToken()?.roll_type === 'admin' && (
                        <li className="w_200 w_xxs_100">
                          <Form.Item
                            label=""
                            name="location_filter"
                            className="custom_select mb-0"
                          >
                            <Select
                              placeholder="Select Location"
                              name="location_filter"
                              value={searchData?.location_filter}
                              onChange={value => {
                                debouncehandleSearchInput(value, currentPage, {
                                  ...searchData,
                                  location_filter: value,
                                });
                                setSearchData({
                                  ...searchData,
                                  location_filter: value,
                                });
                              }}
                              options={searchLocationField}
                            />
                          </Form.Item>
                        </li>
                      )}
                      <li className="align-self-center">
                        <div className="plus_mobile_wrap">
                          <Button
                            className="btn_primary"
                            onClick={() => {
                              setSearchData(searchDataInitialState);
                              form.setFieldsValue(searchDataInitialState);
                              getLeadDataList(true);
                            }}
                          >
                            <ReloadOutlined className="me-2" />
                            <span className="ms-0">Reset Filter</span>
                          </Button>
                        </div>
                      </li>
                      <li className="ms-lg-auto align-self-center">
                        <Dropdown
                          disabled={
                            Object.keys(count)?.length === 0 ? true : false
                          }
                          overlay={menuProps}
                          className="custom_select custom_dropdown"
                        >
                          <Button className="btn_primary">
                            <ExportOutlined className="me-2" />
                            <Space>Export</Space>
                          </Button>
                        </Dropdown>
                      </li>
                      <li className="align-self-center">
                        <div className="plus_mobile_wrap">
                          <Button
                            className="btn_primary"
                            onClick={() => {
                              navigate('/lead');
                            }}
                          >
                            <PlusOutlined className="me-2" />
                            <span className="ms-0">Add Lead</span>
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          <div className="cmn_table_wrap all_lead_table_wrap">
            <Table
              columns={columns}
              dataSource={[...leadList, count]}
              pagination={false}
            />
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalPages * pageSize}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              showSizeChanger
              className="custom_select"
            />
          </div>
        </div>
      </div>
      {/* <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="model_delete_content">
          <div className="delete_img">
            <CloseCircleOutlined />
          </div>
          <p className="text-center mt-3">
            Are you sure you want to delete this item?
          </p>
        </div>
        <div className="footer_button d-flex justify-content-end">
          <Button
            className="btn_border mx-1"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button className="btn_primary mx-1" onClick={() => handelDelete()}>
            Delete
          </Button>
        </div>
      </Modal> */}
    </>
  );
};

export default AllLead;
