import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PhoneWithCountry from 'Components/Auth/PhoneWithCountry';
import {
  createUser,
  setIsUserCreated,
  updateUser,
  viewUser,
} from 'redux/reducers/UserManagement/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

export default function AddUser({ setIsAddUser, editUserId, setEditUserId }) {
  const { state } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberWithoutCountryCode, setPhoneNumberWithoutCountryCode] =
    useState('');
  const { isUserCreated, userDetail, userLoading } = useSelector(
    ({ userData }) => userData,
  );
  const [userData, setUserData] = useState({
    user_name: '',
    user_email: '',
    user_mobile_no: '',
    country_code: '',
    location: '',
  });
  const handlePhoneChange = useCallback((phone, country) => {
    const phoneNumberWithoutCountryCode = phone?.replace(country?.dialCode, '');
    setPhoneNumberWithoutCountryCode(phoneNumberWithoutCountryCode);
    setPhoneNumber(phone);
    setCountryCode(country?.dialCode);
  }, []);
  useEffect(() => {
    if (state?.userId) {
      dispatch(viewUser({ user_id: state?.userId }));
    }
    return () => {
      setUserData({
        user_name: '',
        user_email: '',
        user_mobile_no: '',
        country_code: '',
        location: '',
      });
      setCountryCode('');
      setPhoneNumber('');
      setPhoneNumberWithoutCountryCode('');
    };
  }, [state?.userId, dispatch]);

  const onFinish = useCallback(() => {
    setPhoneError(false);
    if (phoneNumberWithoutCountryCode === '' && countryCode === '') {
      setPhoneError(true);
    } else {
      const payload = {
        ...userData,
        user_mobile_no: phoneNumberWithoutCountryCode,
        country_code: countryCode,
      };
      state?.userId
        ? dispatch(updateUser({ ...payload, user_id: state?.userId }))
        : dispatch(createUser(payload));
    }
  }, [userData, countryCode, phoneNumberWithoutCountryCode]);

  const onFinishFailed = useCallback(errorInfo => {
    if (phoneNumberWithoutCountryCode === '' && countryCode === '') {
      setPhoneError(true);
    }
    // if (
    //   userData.user_mobile_no === '' ||
    //   userData.user_mobile_no === null ||
    //   userData.user_mobile_no === undefined
    // ) {
    //   setPhoneError(true);
    // }

    console.log('Failed:', errorInfo);
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (isUserCreated) {
      handleClear();
      navigate('/user-management');
      dispatch(setIsUserCreated(false));
    }
  }, [dispatch, isUserCreated]);

  const handleClear = useCallback(() => {
    setUserData({
      user_name: '',
      user_email: '',
      user_mobile_no: '',
      country_code: '',
      location: '',
    });
    setCountryCode('91');
    setPhoneNumber('91');
    setPhoneNumberWithoutCountryCode('');
    form?.resetFields();
  }, [form]);

  useEffect(() => {
    if (Object.entries(userDetail)?.length > 0 && state?.userId) {
      const setPhoneNumberWithCode = `+${userDetail?.country_code} ${userDetail?.user_mobile_no}`;
      form.setFieldsValue({
        user_name: userDetail?.user_name,
        user_email: userDetail?.user_email,
        user_mobile_no: userDetail?.user_mobile_no,
        country_code: userDetail?.country_code,
        location: userDetail?.location,
      });
      setUserData({
        user_name: userDetail?.user_name,
        user_email: userDetail?.user_email,
        user_mobile_no: userDetail?.user_mobile_no,
        country_code: userDetail?.country_code,
        location: userDetail?.location,
      });
      setPhoneNumber(setPhoneNumberWithCode);
      setCountryCode(userDetail?.country_code);
      setPhoneNumberWithoutCountryCode(userDetail?.user_mobile_no);
    }
  }, [userDetail]);
  return (
    <>
     <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management - Add User</title>
            <meta name="description" content="A Nimaaya Lead Management application - Add User" />
        </Helmet>
      {userLoading && (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      )}
      <div className="page_main_content">
        <div className="page_inner_content">
          <div className="user_roll_manager_wrap">
            <Form
              form={form}
              name="user_roll_manager"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col xl={3} md={3}>
                  <Form.Item
                    label="User Name"
                    name="user_name"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter User Name"
                      value={userData?.user_name}
                      onChange={handleChange}
                      name="user_name"
                    />
                  </Form.Item>
                </Col>
                <Col xl={3} md={3}>
                  <Form.Item
                    label="User Email"
                    name="user_email"
                    value={userData?.user_email}
                    onChange={handleChange}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                      },
                    ]}
                  >
                    <Input
                      disabled={state?.userId ? true : false}
                      placeholder="Enter User Email"
                      name="user_email"
                    />
                  </Form.Item>
                </Col>
                <Col xl={3} md={3}>
                  <Form.Item
                    label="User Mobile No."
                    className={phoneError ? 'validation_error' : ''}
                    // className="validation_error"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <PhoneWithCountry
                      handlePhoneChange={handlePhoneChange}
                      phoneNumber={phoneNumber}
                      countryCode={countryCode}
                    />
                  </Form.Item>
                </Col>
                <Col xl={3} md={3}>
                  <Form.Item
                    label="User Location"
                    name="location"
                    className="custom_select"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      value={userData?.location}
                      name="location"
                      onChange={value => {
                        setUserData({
                          ...userData,
                          location: value,
                        });
                      }}
                      options={[
                        {
                          value: 'Surat',
                          label: 'Surat',
                        },
                        {
                          value: 'Ahmedabad',
                          label: 'Ahmedabad',
                        },
                        {
                          value: 'Vadodara',
                          label: 'Vadodara',
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="button_group d-flex align-items-center justify-content-center">
                <Button className="btn_primary mx-2" htmlType="submit">
                  {state?.userId ? 'Update' : 'Save'}
                </Button>
                <Button
                  className="btn_gray mx-2"
                  onClick={() => {
                    navigate('/user-management');
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
