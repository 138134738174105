import React from 'react';
import morning from '../../../Img/morning.svg';
import afternoon from '../../../Img/afternoon.svg';
import { Helmet } from 'react-helmet-async';
import evening from '../../../Img/evening.svg';
import { Button } from 'antd';
import LeftLadies from '../../../Img/left_ladies.png';
import RightLadies from '../../../Img/right_ladies.png';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from 'Helper/AuthTokenHelper';
const Dashboard = () => {
  let UserPreferences = getAuthToken();
  const navigate = useNavigate();
  const determineGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return { greetingMsg: 'Good morning!', greetingImg: morning };
    } else if (currentHour >= 12 && currentHour < 18) {
      return { greetingMsg: 'Good afternoon!', greetingImg: afternoon };
    } else {
      return { greetingMsg: 'Good evening!', greetingImg: evening };
    }
  };
  const { greetingMsg, greetingImg } = determineGreeting();

  return (
    <>
    <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management - Dashboard</title>
            <meta name="description" content="A Nimaaya Lead Management - Dashboard" />
        </Helmet>
      <div className="page_main_content lead_managment_main_wrap">
        <div className="lead_managment_top">
          <h2 className="font_26">
            {greetingImg && <img src={greetingImg && greetingImg} alt="" />}
            &nbsp;
            {greetingMsg && greetingMsg}&nbsp;
            {UserPreferences?.user_name}
          </h2>
          <p className="font_18">Have a nice day at work!!</p>
          <div className="lead_managment_img_wrap">
            <img src={LeftLadies} alt="Img" className="left_ladies" />
            <span className="gif_img"></span>
            <img src={RightLadies} alt="Img" className="right_ladies" />
          </div>
        </div>
        <div className="lead_button_wrap">
          <div className="button_group d-flex align-items-center justify-content-center">
            <Button
              className="btn_primary mx-3"
              onClick={() => navigate('/lead')}
            >
              Add Lead
            </Button>
            <Button
              className="btn_gray"
              onClick={() => navigate('/lead-report')}
            >
              Update Lead
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
