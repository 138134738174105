import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Switch } from 'antd';
import HomeIcon from '../../Img/HomeIcon.svg';
import LeadReport from '../../Img/lead_report_icon.svg';
import MenuDot from '../../Img/listdot.svg';
import LeadManagment from '../../Img/lead-managment.svg';
import patientregistration from '../../Img/patient-registration.svg';
import LogoIcon from '../../Img/logo-icon.svg';
import LogoText from '../../Img/logo-text.svg';
import { Link, useLocation } from 'react-router-dom';
import SelectArrow from '../../Img/select-arrow.svg';
import UserImg from '../../Img/user1.jpg';
import DarkIcon from '../../Img/dark-btn.svg';
import LightIcon from '../../Img/dark-mode.svg';
import { ThemeContext } from '../../contexts/theme-context';
import { getAuthToken } from 'Helper/AuthTokenHelper';
const sideMenu = [
  {
    key: 'tmp-0',
    isuser: true,
    isadmin: true,
    module_name: '/dashboard',
    moduleGroup: ['/dashboard'],
    module_actual_name: 'Home',
    images: HomeIcon,
  },
  {
    key: 'tmp-0.1',
    isuser: true,
    isadmin: true,
    module_name: '/lead-report',
    moduleGroup: ['/lead-report'],
    module_actual_name: 'Lead Management',
    images: LeadReport,
  },
  // {
  //   key: 'tmp-1',
  //   isadmin: true,
  //   isuser: false,
  //   module_name: '/user-management',
  //   moduleGroup: ['/user-management', '/add-user', '/edit-user'],
  //   module_actual_name: 'User Management',
  //   images: patientregistration,
  // },
  // {
  //   key: 'tmp-2',
  //   isadmin: true,
  //   isuser: true,
  //   // module_name: '/lead-report',
  //   module_actual_name: 'Management',
  //   images: LeadReport,
  //   submenu: [
  //     {
  //       key: 'tmp-0.1',
  //       isuser: true,
  //       isadmin: true,
  //       module_name: '/lead-report',
  //       moduleGroup: ['/lead-report'],
  //       module_actual_name: 'Lead Management',
  //       images: MenuDot,
  //     },
  //   ],
  // },
];

export default function Sidebar({ collapsed, setCollapsed }) {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const { SubMenu } = Menu;
  const UserPreferences = getAuthToken();
  const [selectedTab, setSelectedTab] = useState([]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const deviceCollapsed = pathName => {
    // setCollapsed(!collapsed);
    const detectDeviceType = () => {
      if (
        /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      )
        return 'mobile';
      else if (/iPad|iPod/i.test(navigator.userAgent)) return 'tablet';
      else return 'browser';
    };
    const device = detectDeviceType();
    if (device !== 'browser' && pathName) {
      setCollapsed(false);
    }
  };
  const sideMenuItems = useMemo(() => {
    const viewTabs = sideMenu?.map((item, index) => {
      if (
        (UserPreferences.roll_type === 'admin' && item.isadmin) ||
        (UserPreferences.roll_type === 'user' && item.isuser)
      ) {
        const menuItem = {
          label: (
            <Link
              to={item.module_name}
              onClick={() => deviceCollapsed(item.module_name)}
            >
              <span>{item.module_actual_name}</span>
            </Link>
          ),
          key: item.key,
          icon: <img src={item.images} alt={item.key} />,
        };
        if (item.submenu && item.submenu.length > 0) {
          menuItem.subMenuItems = item.submenu.map(sub => ({
            label: (
              <Link
                to={sub.module_name}
                onClick={() => deviceCollapsed(sub.module_name)}
              >
                <span>{sub.module_actual_name}</span>
              </Link>
            ),
            key: sub.key,
            icon: <img src={sub.images} alt={sub.key} />,
          }));
        }
        return menuItem;
      }
      return null;
    });
    return viewTabs.filter(item => item !== null);
    // return (UserPreferences.roll_type === 'admin' &&
    //   items.isadmin === true) ||
    //   (UserPreferences.roll_type === 'user' && items.isuser === true)
    //   ? {
    //       label: (
    //         <Link to={items.module_name}>
    //           <span>{items?.module_actual_name}</span>
    //         </Link>
    //       ),
    //       key: items.module_name.key,
    //       icon: <img src={items.images} alt={index} />,

    //     } && Object.entries(items?.submenu)?.length > 0 && items?.submenu.map((sub)=>{
    //       return
    //     })
    //   : '';
    // }) || [];
    // return viewTabs;
  }, [UserPreferences, deviceCollapsed]);

  const renderMenu = menuItems => {
    return menuItems.map(item => {
      if (item.subMenuItems && item.subMenuItems.length > 0) {
        return (
          <SubMenu key={item.key} title={item.label} icon={item.icon}>
            {renderMenu(item.subMenuItems)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      );
    });
  };

  useEffect(() => {
    let isAvailable = null;
    sideMenu.map(item => {
      item?.moduleGroup?.map(item2 => {
        if (item2 === location.pathname) {
          isAvailable = item;
        }
      });
      if (!isAvailable && item?.submenu?.length > 0) {
        item?.submenu?.map(item3 => {
          item3?.moduleGroup?.map(item4 => {
            if (item4 === location.pathname) {
              isAvailable = item3;
            }
          });
          if (!isAvailable && item3?.submenu?.length > 0) {
            item3?.submenu?.map(item5 => {
              item5?.moduleGroup?.map(item6 => {
                if (item6 === location.pathname) {
                  isAvailable = item5;
                }
              });
            });
          }
        });
      }
    });
    // let selectedValue = sideMenu.find(
    //   item => {
    //     let isAvailable = item?.moduleGroup?.find(item);
    //     if (item?.moduleGroup?.includes(window.location.pathname)) {
    //       return item;
    //     } else if (item?.subMenuItems?.length > 0) {
    //       const obj = item?.subMenuItems?.find(item2 =>
    //         item2?.moduleGroup?.includes(window.location.pathname),
    //       );
    //       if (obj) {
    //         return obj;
    //       }
    //     }
    //   },
    //   // item?.moduleGroup?.includes(window.location.pathname),
    // );
    if (isAvailable) {
      setSelectedTab(isAvailable?.key);
    } else {
      setSelectedTab('tmp-0');
    }
  }, [location.pathname]);
  const onClick = useCallback(e => {
    setSelectedTab(e.key);
  }, []);
  const userDropDown = [
    {
      key: 'Logout',
      label: (
        <Link to="/" className="language_items">
          Logout
        </Link>
      ),
    },
  ];

  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  return (
    <div className="sidebar_inner">
      <div className="sidebar_logo">
        <div className="Logo_icon">
          <img src={LogoIcon} alt="" />
        </div>
        <div className="Logo_text ps-2">
          <img src={LogoText} alt="" />
        </div>
      </div>
      <div className="user_dropdown_mobile d-none">
        <Dropdown
          menu={{
            items: userDropDown,
          }}
          placement="bottomLeft"
          className="user_dropdown"
        >
          <Button>
            <span className="user_img me-2">
              <img src={UserImg} alt="" />
            </span>
            {UserPreferences?.user_name}
            <img src={SelectArrow} className="ms-2 down_arrow" alt="" />
          </Button>
        </Dropdown>
      </div>
      <Button
        className="toggle_btn btn_transparent mobile_toggle_btn"
        onClick={toggleCollapsed}
      >
        <CloseOutlined />
      </Button>
      <Menu
        mode="inline"
        onClick={onClick}
        // defaultSelectedKeys={['1']}
        //  defaultOpenKeys={['sub1']}
        // onOpenChange={onOpenChange}
        selectedKeys={[selectedTab]}
        //  openKeys={[]}
      >
        {renderMenu(sideMenuItems)}
      </Menu>
      {/* <Menu
        selectedKeys={[selectedTab]}
        onClick={onClick}
        mode="inline"
        inlineCollapsed={collapsed}
        items={sideMenuItems}
      /> */}
      <div className="mobile_bottom_menu d-block d-md-none">
        <ul>
          <li>
            <Button
              type="button"
              className="btn_transparent w-100 d-flex justify-content-between align-items-center"
            >
              <span>
                {theme === 'light' ? (
                  <img src={DarkIcon} className="dark_icon me-2" alt="" />
                ) : (
                  <img src={LightIcon} className="light_icon me-2" alt="" />
                )}
                Dark Mode
              </span>
              <Switch
                onChange={handleThemeChange}
                defaultChecked={theme === 'light' ? false : true}
              />
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}
