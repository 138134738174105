import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
  userList: [],
  totolOfUser :0,
  userLoading:false,
  isUserCreated:false,
  isUserUpdated:false,
  userDetail: {},
};

export const getUserListData = createAsyncThunk(
  'user/list',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit } = props;
      axios
        .post(`user/list/${start}/${limit}`)
        .then(res => {
          if (res?.data?.err === 0) {
            if (res.data?.data?.users?.length > 0) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            reject([]);
            toast.error(res?.data?.msg);
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);
export const createUser = createAsyncThunk(
  "admin/create-user",
  (user, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("user/add-member", user)
        .then((res) => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data?.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  }
);

export const viewUser = createAsyncThunk(
  "admin/view-user",
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/view`,props)
        .then((res) => {
          if (res?.data?.err === 0) {
            resolve(res.data?.data);
          } else {
            reject({});
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  }
);

export const updateUser = createAsyncThunk(
  "admin/update-user",
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("user/update", props)
        .then((res) => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data?.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setIsUserCreated: (state, action) => {
      state.isUserCreated = action.payload;
    },
  },
  extraReducers: {
    [getUserListData.pending]: state => {
      state.userList = [];
      state.userLoading = true;
    },
    [getUserListData.rejected]: state => {
      state.userList = [];
      state.userLoading = false;
    },
    [createUser.pending]: (state) => {
      state.isUserCreated = false;
      state.userLoading = true;
    },
    [createUser.rejected]: (state) => {
      state.isUserCreated = false;
      state.userLoading = false;
    },
    [createUser.fulfilled]: (state) => {
      state.isUserCreated = true;
      state.userLoading = false;
    },    
    [getUserListData.fulfilled]: (state, action) => {
      let userListData = [];
      let userListTotal = 0;
      if (action.payload?.users?.length > 0) {
        userListData = action.payload.users;
        userListTotal = action.payload.total_users;
      }
      state.userList = userListData;
      state.totolOfUser = userListTotal;
      state.userLoading = false;
    },
    [updateUser.pending]: (state) => {
      state.isUserCreated = false;
      state.userLoading = true;
    },
    [updateUser.rejected]: (state) => {
      state.isUserCreated = false;
      state.userLoading = false;
    },
    [updateUser.fulfilled]: (state) => {
      state.isUserCreated = true;
      state.userLoading = false;
    },
    [viewUser.pending]: (state) => {
      state.userDetail = {};
      state.userLoading = true;
    },
    [viewUser.rejected]: (state) => {
      state.userDetail = {};
      state.userLoading = false;
    },
    [viewUser.fulfilled]: (state, action) => {
      state.userDetail = action.payload || {};
      state.userLoading = false;
    }
  },
});

export const { setUserList, setUserDetail,setIsUserCreated } = userSlice.actions;
export default userSlice.reducer;
