import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Spin,
  message,
} from 'antd';
import { Col, Row } from 'react-bootstrap';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import {
  createLeadDetails,
  getAddress,
  updateLeadData,
  viewLeadData,
} from 'redux/reducers/LeadManagement/lead.slice';
import { useLocation, useNavigate } from 'react-router';
import { dateFormat, docterList, locationField, typeOfPatient } from './Data';
import { getAuthToken } from 'Helper/AuthTokenHelper';
const patientinginitialdata = {
  patient_name: '',
  patient_dob: '',
  patient_gender: '',
  patient_phone_number: '',
  reference_type: '',
  reference: '',
  doctor_name: '',
  type_of_patient: '',
  investigation_of_female: null,
  investigation_of_female_date: null,
  investigation_of_female_location: null,
  investigation_of_male: null,
  investigation_of_male_date: null,
  investigation_of_male_location: null,
  semen_analysis: null,
  semen_analysis_date: null,
  semen_analysis_location: null,
  hysteroscopy: null,
  hysteroscopy_date: null,
  hysteroscopy_location: null,
  type_of_treatment: null,
  type_of_treatment_date: null,
  type_of_treatment_location: null,
  stimulation_started: null,
  stimulation_started_date: null,
  stimulation_started_location: null,
  opu: null,
  opu_date: null,
  opu_location: null,
  outcome: null,
  outcome_date: null,
  outcome_location: null,
  package_id: '',
  pincode: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  date_of_surgery: null,
  surgery_room_type: null,
  surgery_done_by: '',
  date_of_delivery: null,
  delivery_room_type: null,
  delivery_done_by: '',
  type_of_delivery: null,
  location_of_delivery: null,
  date_of_consultation: null,
  customer_is_open: true,
  type_of_consultation: 'In person',
};
const AddLead = () => {
  const { leadDetails, leadLoading, leadAddress, addressLoading } = useSelector(
    ({ lead }) => lead,
  );
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const pincodeLength = 1;
  const { TextArea } = Input;
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [manualAddress, setManualAddress] = useState(true);
  const [patientInfo, setPatientInfo] = useState(
    patientinginitialdata,
    //   {
    //   patient_name: '',
    //   patient_dob: '',
    //   patient_gender: '',
    //   patient_phone_number: '',
    //   reference_type: '',
    //   reference: '',
    //   doctor_name: '',
    //   type_of_patient: '',
    //   investigation_of_female: null,
    //   investigation_of_female_date: null,
    //   investigation_of_female_location: null,
    //   investigation_of_male: null,
    //   investigation_of_male_date: null,
    //   investigation_of_male_location: null,
    //   semen_analysis: null,
    //   semen_analysis_date: null,
    //   semen_analysis_location: null,
    //   hysteroscopy: null,
    //   hysteroscopy_date: null,
    //   hysteroscopy_location: null,
    //   type_of_treatment: null,
    //   type_of_treatment_date: null,
    //   type_of_treatment_location: null,
    //   stimulation_started: null,
    //   stimulation_started_date: null,
    //   stimulation_started_location: null,
    //   opu: null,
    //   opu_date: null,
    //   opu_location: null,
    //   outcome: null,
    //   outcome_date: null,
    //   outcome_location: null,
    //   package_id: '',
    //   pincode: '',
    //   address1: '',
    //   address2: '',
    //   city: '',
    //   state: '',
    //   country: '',
    //   date_of_surgery: null,
    //   surgery_room_type: null,
    //   surgery_done_by: '',
    //   date_of_delivery: null,
    //   delivery_room_type: null,
    //   delivery_done_by: '',
    //   type_of_delivery: null,
    //   location_of_delivery: null,
    //   date_of_consultation: null,
    //   customer_is_open: true,
    //   type_of_consultation :""
    // }
  );
  const responseSet = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const packItem = useMemo(() => {
    return {
      package_date: null,
      package_location: '',
      package_amount: '',
      receipt_no: '',
    };
  }, []);
  const [packData, setPackData] = useState([packItem]);

  const onFinish = value => {
    const editPayload = {
      ...patientInfo,
      package: packData,
      lead_id: state?.leadId,
      location: checkedLocations,
    };
    if (state?.leadId) {
      dispatch(updateLeadData(editPayload));
    } else {
      const payload = {
        ...patientInfo,
        package: packData,
        location: checkedLocations,
      };
      dispatch(createLeadDetails(payload));
    }
    navigate('/lead-report');
  };

  const onFinishFailed = errorInfo => {
    const firstErrorField = document.querySelector('.ant-form-item-has-error');
    if (firstErrorField) {
      firstErrorField.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const transformData = data => {
    return data?.reduce((result, item, index) => {
      const packageAmountKey = `package_amount${index}`;
      const packageDateKey = `package_date${index}`;
      const receiptNoKey = `receipt_no${index}`;
      const loactionKey = `package_location${index}`;
      result[packageAmountKey] = item.package_amount;
      result[packageDateKey] =
        item?.package_date === null
          ? null
          : dayjs(moment(item?.package_date).format(dateFormat), dateFormat);
      result[receiptNoKey] = item?.receipt_no;
      result[loactionKey] = item?.package_location;
      return result;
    }, {});
  };
  const debouncehandleSearchInput = useCallback(
    _.debounce(value => {
      if (value) {
        dispatch(getAddress({ pincode: Number(value) }));
      } else {
        form.setFieldsValue({
          city: '',
          state: '',
          country: '',
        });
      }
    }, 800),
    [dispatch],
  );
  useEffect(() => {
    setManualAddress(true);
    if (Object.keys(leadAddress)?.length > 0) {
      form.setFieldsValue({
        city: leadAddress?.city,
        state: leadAddress?.state,
        country: leadAddress?.country,
      });
      setPatientInfo({
        ...patientInfo,
        city: leadAddress?.city,
        state: leadAddress?.state,
        country: leadAddress?.country,
      });
    } else {
      setManualAddress(false);
      form.setFieldsValue({
        city: '',
        state: '',
        country: '',
      });
      setPatientInfo({
        ...patientInfo,
        city: '',
        state: '',
        country: '',
      });
    }
  }, [form, leadAddress]);
  const locationOnChange = useCallback(
    location => {
      if (checkedLocations.includes(location)) {
        setCheckedLocations(checkedLocations.filter(loc => loc !== location));
      } else {
        setCheckedLocations([...checkedLocations, location]);
      }
    },
    [checkedLocations],
  );

  const handelfeesinput = useCallback(() => {
    const updatedPackData = [
      ...packData,
      JSON.parse(JSON.stringify({ ...packItem })),
    ];
    setPackData(updatedPackData);
  }, [packItem, packData]);

  const onChangeFeesPackage = useCallback(
    (index, field, val) => {
      let data = [...packData];
      if (!Object.isExtensible(data[index])) {
        data[index] = { ...data[index], [field]: val };
      } else {
        data[index][field] = val;
      }
      setPackData(data);
    },
    [packData],
  );

  const onDeleteFeesPackage = useCallback(
    index => {
      let data = [...packData];
      const updatedPackData = data?.filter((item, i) => i !== index);
      setPackData(updatedPackData);
      const newPackageData = transformData(updatedPackData) || {};
      if (Object.entries(newPackageData)?.length > 0)
        form.setFieldsValue({ ...newPackageData });
    },
    [form, packData],
  );
  const clearPatientBasicInformationForm = useCallback(() => {
    setPatientInfo(
      patientinginitialdata,
      //   {
      //   patient_name: '',
      //   patient_dob: '',
      //   patient_gender: '',
      //   patient_phone_number: '',
      //   reference_type: '',
      //   reference: '',
      //   doctor_name: '',
      //   type_of_patient: '',
      //   investigation_of_female: null,
      //   investigation_of_female_date: null,
      //   investigation_of_female_location: null,
      //   investigation_of_male: null,
      //   investigation_of_male_date: null,
      //   investigation_of_male_location: null,
      //   semen_analysis: null,
      //   semen_analysis_date: null,
      //   semen_analysis_loaction: null,
      //   hysteroscopy: null,
      //   hysteroscopy_date: null,
      //   hysteroscopy_location: null,
      //   type_of_treatment: null,
      //   type_of_treatment_date: null,
      //   type_of_treatment_location: null,
      //   stimulation_started: null,
      //   stimulation_started_date: null,
      //   stimulation_started_location: null,
      //   opu: null,
      //   opu_date: null,
      //   opu_location: null,
      //   outcome: null,
      //   outcome_date: null,
      //   outcome_location: null,
      //   package_id: '',
      //   pincode: '',
      //   address1: '',
      //   address2: '',
      //   city: '',
      //   state: '',
      //   country: '',
      //   date_of_surgery: null,
      //   surgery_room_type: null,
      //   surgery_done_by: '',
      //   date_of_delivery: null,
      //   delivery_room_type: null,
      //   delivery_done_by: '',
      //   type_of_delivery: null,
      //   location_of_delivery: null,
      //   date_of_consultation: null,
      //   customer_is_open: true,
      // type_of_consultation :""
      // }
    );
    setPackData(packData);
    form.resetFields();
  }, [packData, form]);

  useEffect(() => {
    if (state?.leadId) {
      dispatch(viewLeadData({ lead_id: state?.leadId }));
    }
  }, [dispatch, state?.leadId]);

  useEffect(() => {
    if (Object.entries(leadDetails)?.length > 0 && state?.leadId) {
      const newPackageData = transformData(leadDetails?.packages) || {};
      form.setFieldsValue({
        patient_name: leadDetails?.patient_name,
        patient_dob: dayjs(
          moment(leadDetails?.patient_dob).format(dateFormat),
          dateFormat,
        ),
        date_of_consultation: dayjs(
          moment(leadDetails?.date_of_consultation).format(dateFormat),
          dateFormat,
        ),
        patient_gender: leadDetails?.patient_gender,
        patient_phone_number: leadDetails?.patient_phone_number,
        reference_type: leadDetails?.reference_type,
        reference: leadDetails?.reference,
        doctor_name: leadDetails?.doctor_name,
        type_of_patient: leadDetails?.type_of_patient,
        investigation_of_female: leadDetails?.investigation_of_female,
        investigation_of_female_date:
          leadDetails?.investigation_of_female_date === null
            ? null
            : dayjs(
                moment(leadDetails?.investigation_of_female_date).format(
                  dateFormat,
                ),
                dateFormat,
              ),
        investigation_of_female_location:
          leadDetails?.investigation_of_female_location,
        investigation_of_male: leadDetails?.investigation_of_male,
        investigation_of_male_date:
          leadDetails?.investigation_of_male_date === null
            ? null
            : dayjs(
                moment(leadDetails?.investigation_of_male_date).format(
                  dateFormat,
                ),
                dateFormat,
              ),
        investigation_of_male_location:
          leadDetails?.investigation_of_male_location,
        semen_analysis: leadDetails?.semen_analysis,
        semen_analysis_date:
          leadDetails?.semen_analysis_date === null
            ? null
            : dayjs(
                moment(leadDetails?.semen_analysis_date).format(dateFormat),
                dateFormat,
              ),
        semen_analysis_location: leadDetails?.semen_analysis_location,
        hysteroscopy: leadDetails?.hysteroscopy,
        hysteroscopy_date:
          leadDetails?.hysteroscopy_date === null
            ? null
            : dayjs(
                moment(leadDetails?.hysteroscopy_date).format(dateFormat),
                dateFormat,
              ),
        hysteroscopy_location: leadDetails?.hysteroscopy_location,
        type_of_treatment: leadDetails?.type_of_treatment,
        type_of_treatment_date:
          leadDetails?.type_of_treatment_date === null
            ? null
            : dayjs(
                moment(leadDetails?.type_of_treatment_date).format(dateFormat),
                dateFormat,
              ),
        type_of_treatment_location: leadDetails?.type_of_treatment_location,
        stimulation_started: leadDetails?.stimulation_started,
        stimulation_started_date:
          leadDetails?.stimulation_started_date === null
            ? null
            : dayjs(
                moment(leadDetails?.stimulation_started_date).format(
                  dateFormat,
                ),
                dateFormat,
              ),
        stimulation_started_location: leadDetails?.stimulation_started_location,
        opu: leadDetails?.opu,
        opu_date:
          leadDetails?.opu_date === null
            ? null
            : dayjs(
                moment(leadDetails?.opu_date).format(dateFormat),
                dateFormat,
              ),
        opu_location: leadDetails?.opu_location,
        outcome: leadDetails?.outcome,
        outcome_date:
          leadDetails?.outcome_date === null
            ? null
            : dayjs(
                moment(leadDetails?.outcome_date).format(dateFormat),
                dateFormat,
              ),
        outcome_location: leadDetails?.outcome_location,
        ...newPackageData,
        package_id: leadDetails?.package_id,
        pincode: leadDetails?.pincode,
        address1: leadDetails?.address1,
        address2: leadDetails?.address2,
        city: leadDetails?.city,
        state: leadDetails?.state,
        country: leadDetails?.country,
        date_of_surgery:
          leadDetails?.date_of_surgery === null
            ? null
            : dayjs(
                moment(leadDetails?.date_of_surgery).format(dateFormat),
                dateFormat,
              ),
        surgery_room_type: leadDetails?.surgery_room_type,
        surgery_done_by: leadDetails?.surgery_done_by,
        date_of_delivery:
          leadDetails?.date_of_delivery === null
            ? null
            : dayjs(
                moment(leadDetails?.date_of_delivery).format(dateFormat),
                dateFormat,
              ),
        delivery_room_type: leadDetails?.delivery_room_type,
        delivery_done_by: leadDetails?.delivery_done_by,
        type_of_delivery: leadDetails?.type_of_delivery,
        location_of_delivery: leadDetails?.location_of_delivery,
        customer_is_open: leadDetails?.customer_is_open,
        type_of_consultation: leadDetails?.type_of_consultation || '',
      });
      setPatientInfo({
        patient_name: leadDetails?.patient_name,
        patient_dob: moment(new Date(leadDetails?.patient_dob)).format(
          dateFormat,
        ),

        date_of_consultation: moment(
          new Date(leadDetails?.date_of_consultation),
        ).format(dateFormat),
        patient_gender: leadDetails?.patient_gender,
        patient_phone_number: leadDetails?.patient_phone_number,
        reference_type: leadDetails?.reference_type,
        reference: leadDetails?.reference,
        doctor_name: leadDetails?.doctor_name,
        type_of_patient: leadDetails?.type_of_patient,
        investigation_of_female: leadDetails?.investigation_of_female,
        investigation_of_female_date:
          leadDetails?.investigation_of_female_date === null
            ? null
            : moment(
                new Date(leadDetails?.investigation_of_female_date),
              ).format(dateFormat),
        investigation_of_female_location:
          leadDetails?.investigation_of_female_location,
        investigation_of_male: leadDetails?.investigation_of_male,
        investigation_of_male_date:
          leadDetails?.investigation_of_male_date === null
            ? null
            : moment(new Date(leadDetails?.investigation_of_male_date)).format(
                dateFormat,
              ),
        investigation_of_male_location:
          leadDetails?.investigation_of_male_location,
        semen_analysis: leadDetails?.semen_analysis,
        semen_analysis_date:
          leadDetails?.semen_analysis_date === null
            ? null
            : moment(new Date(leadDetails?.semen_analysis_date)).format(
                dateFormat,
              ),
        semen_analysis_location: leadDetails?.semen_analysis_location,
        hysteroscopy: leadDetails?.hysteroscopy,
        hysteroscopy_date:
          leadDetails?.hysteroscopy_date === null
            ? null
            : moment(new Date(leadDetails?.hysteroscopy_date)).format(
                dateFormat,
              ),
        hysteroscopy_location: leadDetails?.hysteroscopy_location,
        type_of_treatment: leadDetails?.type_of_treatment,
        type_of_treatment_date:
          leadDetails?.type_of_treatment_date === null
            ? null
            : moment(new Date(leadDetails?.type_of_treatment_date)).format(
                dateFormat,
              ),
        type_of_treatment_location: leadDetails?.type_of_treatment_location,
        stimulation_started: leadDetails?.stimulation_started,
        stimulation_started_date:
          leadDetails?.stimulation_started_date === null
            ? null
            : moment(new Date(leadDetails?.stimulation_started_date)).format(
                dateFormat,
              ),
        stimulation_started_location: leadDetails?.stimulation_started_location,
        opu: leadDetails?.opu,
        opu_date:
          leadDetails?.opu_date === null
            ? null
            : moment(new Date(leadDetails?.opu_date)).format(dateFormat),
        opu_location: leadDetails?.opu_location,
        outcome: leadDetails?.outcome,
        outcome_date:
          leadDetails?.outcome_date === null
            ? null
            : moment(new Date(leadDetails?.outcome_date)).format(dateFormat),
        outcome_location: leadDetails?.outcome_location,
        package_id: leadDetails?.package_id,
        pincode: leadDetails?.pincode,
        address1: leadDetails?.address1,
        address2: leadDetails?.address2,
        city: leadDetails?.city,
        state: leadDetails?.state,
        country: leadDetails?.country,
        date_of_surgery:
          leadDetails?.date_of_surgery === null
            ? null
            : moment(new Date(leadDetails?.date_of_surgery)).format(dateFormat),
        surgery_room_type: leadDetails?.surgery_room_type,
        surgery_done_by: leadDetails?.surgery_done_by,
        date_of_delivery:
          leadDetails?.date_of_delivery === null
            ? null
            : moment(new Date(leadDetails?.date_of_delivery)).format(
                dateFormat,
              ),
        delivery_room_type: leadDetails?.delivery_room_type,
        delivery_done_by: leadDetails?.delivery_done_by,
        type_of_delivery: leadDetails?.type_of_delivery,
        location_of_delivery: leadDetails?.location_of_delivery,
        customer_is_open: leadDetails?.customer_is_open,
        type_of_consultation: leadDetails?.type_of_consultation || '',
      });
      setPackData(leadDetails?.packages);
      setCheckedLocations(leadDetails?.location);
    }
  }, [leadDetails, form, state?.leadId]);

  // const confirm = e => {
  //   onDeleteFeesPackage(e);
  // };
  const cancel = e => {};

  return (
    <>
      <Helmet defaultTitle="Nimaaya Lead Management">
        <title>
          Nimaaya Lead Management - {state?.leadId ? 'Edit Lead' : 'Add Lead'}
        </title>
        <meta
          name="description"
          content={`A Nimaaya Lead Management application - ${
            state?.leadId ? 'Edit Lead' : 'Add Lead'
          }`}
        />
      </Helmet>
      {leadLoading ||
        (addressLoading && (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        ))}
      <div className="page_main_content lead_managment_main_wrap">
        <div>
          <div>
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
              autoComplete="off"
            >
              <Row>
                <Col lg={6} className="mb-lg-0 mb-4">
                  <div className="page_inner_content">
                    <div className="form_process_wrapper">
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Patient Registration</h3>
                        <Row>
                          <Col xs={6}>
                            <Form.Item
                              label="Full Name"
                              name="patient_name"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Full Name"
                                value={patientInfo.patient_name}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    patient_name: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="Phone Number"
                              name="patient_phone_number"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Enter Phone Number"
                                value={patientInfo.patient_phone_number}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    patient_phone_number: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="DOB"
                              name="patient_dob"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <DatePicker
                                disabledDate={current =>
                                  current.isAfter(moment().subtract(0, 'day'))
                                }
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.patient_dob,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    patient_dob: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="Gender"
                              name="patient_gender"
                              className="custom_select"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                options={[
                                  { value: 'Male', label: 'Male' },
                                  {
                                    value: 'Female',
                                    label: 'Female',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  },
                                ]}
                                name="patient_gender"
                                value={patientInfo.patient_gender}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    patient_gender: value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {getAuthToken()?.roll_type === 'admin' && (
                            <Col xs={12}>
                              <div className="checkbox_group_wrap mb-4">
                                <label className="d-block mb-3">Location</label>
                                <ul>
                                  {locationField.map((items, index) => {
                                    return (
                                      <li key={index}>
                                        <Checkbox
                                          onChange={() =>
                                            locationOnChange(items?.label)
                                          }
                                          checked={checkedLocations.includes(
                                            items?.value,
                                          )}
                                        >
                                          {items?.label}
                                        </Checkbox>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </Col>
                          )}
                          <Col xs={12}>
                            <Row>
                              <Col sm={6}>
                                <Form.Item
                                  label="Date of consultation"
                                  name="date_of_consultation"
                                  className="custom_select"
                                  rules={[
                                    {
                                      required: true,
                                      message: '',
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabledDate={current =>
                                      current.isAfter(
                                        moment().subtract(0, 'day'),
                                      )
                                    }
                                    format={{
                                      format: 'DD-MM-YYYY',
                                      type: 'mask',
                                    }}
                                    value={dayjs(
                                      patientInfo?.date_of_consultation,
                                      dateFormat,
                                    )}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        date_of_consultation: value
                                          ? moment(new Date(value)).format(
                                              dateFormat,
                                            )
                                          : null,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col sm={6}>
                                <div className="round_radio_main_wrapper">
                                  <div className="round_radio_wrapper">
                                    <Form.Item
                                      label="Type of consultation"
                                      name="type_of_consultation"
                                      className="custom_select"
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: '',
                                      //   },
                                      // ]}
                                    >
                                      <Radio.Group
                                        className="mt-2"
                                        name="type_of_consultation"
                                        value={patientInfo.type_of_consultation}
                                        defaultValue={'In person'}
                                        onChange={e =>
                                          setPatientInfo({
                                            ...patientInfo,
                                            type_of_consultation:
                                              e.target.value,
                                          })
                                        }
                                      >
                                        <Radio value={'In person'}>
                                          In person
                                        </Radio>
                                        <Radio value={'Video'}>Video</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Reference</h3>
                        <Row>
                          <Col xs={6}>
                            <Form.Item
                              label="Reference Type"
                              name="reference_type"
                              className="custom_select"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                name="reference_type"
                                value={patientInfo.reference_type}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    reference_type: value,
                                  });
                                }}
                                options={[
                                  { value: 'Doctor', label: 'Doctor' },
                                  {
                                    value: 'Newspaper advertisement',
                                    label: 'Newspaper advertisement',
                                  },
                                  {
                                    value: 'Radio',
                                    label: 'Radio',
                                  },
                                  {
                                    value: 'Instagram',
                                    label: 'Instagram',
                                  },
                                  {
                                    value: 'Google Search',
                                    label: 'Google Search',
                                  },

                                  {
                                    value: 'Patient',
                                    label: 'Patient',
                                  },
                                  {
                                    value: 'Friends / Relative',
                                    label: 'Friends / Relative',
                                  },
                                  {
                                    value: 'Camp',
                                    label: 'Camp',
                                  },
                                  {
                                    value: 'NWF',
                                    label: 'NWF',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item label="Reference" name="reference">
                              <Input
                                placeholder="Enter Reference"
                                value={patientInfo?.reference}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    reference: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Address</h3>
                        <Row>
                          <Col sm={6}>
                            <Form.Item
                              label="Address1"
                              name="address1"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <TextArea
                                rows={2}
                                placeholder="Enter address"
                                value={patientInfo?.address1}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    address1: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item label="Address2" name="address2">
                              <TextArea
                                rows={2}
                                placeholder="Enter address"
                                value={patientInfo?.address2}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    address2: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="Pincode"
                              name="pincode"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: '',
                              //   },
                              //   {
                              //     pattern: /\d+/,
                              //     message: 'Pincode must be 1 digits.',
                              //   },
                              // ]}
                            >
                              <Input
                                type="number"
                                name="pincode"
                                placeholder="Enter Reference"
                                value={patientInfo?.pincode}
                                onWheel={e => e.target.blur()}
                                onKeyDown={e => {
                                  if (
                                    e.key === 'ArrowUp' ||
                                    e.key === 'ArrowDown'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={e => {
                                  debouncehandleSearchInput(e.target.value);
                                  setPatientInfo({
                                    ...patientInfo,
                                    pincode: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="City"
                              name="city"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                  // pattern: /^[A-Za-z]+$/,
                                  pattern: /^[A-Za-z0-9\s]+$/,
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter City"
                                name="city"
                                value={leadAddress?.city}
                                // disabled={manualAddress}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    city: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="State"
                              name="state"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                  // pattern: /^[A-Za-z]+$/,
                                  pattern: /^[A-Za-z0-9\s]+$/,
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter State"
                                name="state"
                                value={leadAddress?.state}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    state: e.target.value,
                                  });
                                }}
                                // disabled={manualAddress}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              label="Country "
                              name="country"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                  pattern: /^[A-Za-z]+$/,
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Country"
                                name="country"
                                value={leadAddress?.country}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    country: e.target.value,
                                  });
                                }}
                                // disabled={manualAddress}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">
                          Doctor Name<span className="validation_start">*</span>
                        </h3>
                        <Row>
                          <Col md={6}>
                            <Form.Item
                              name="doctor_name"
                              className="custom_select"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                name="doctor_name"
                                value={patientInfo?.doctor_name}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    doctor_name: value,
                                  });
                                }}
                                options={docterList}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">
                          Type Of Patient
                          <span className="validation_start">*</span>
                        </h3>
                        <Row className="grid_wrapper">
                          <Col md={6}>
                            <Form.Item
                              name="type_of_patient"
                              className="custom_select"
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo?.type_of_patient}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    type_of_patient: value,
                                  });
                                }}
                                options={typeOfPatient}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Investigation Of Female</h3>
                        <Row>
                          <Col md={5}>
                            <Form.Item
                              name="investigation_of_female"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo?.investigation_of_female}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_female: value,
                                  });
                                }}
                                options={responseSet}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="investigation_of_female_date">
                              <DatePicker
                                name="investigation_of_female_date"
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.investigation_of_female_date,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_female_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="investigation_of_female_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={
                                  patientInfo.investigation_of_female_location
                                }
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_female_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="page_inner_content">
                    <div className="form_process_wrapper">
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Investigation Of Male</h3>
                        <Row>
                          <Col md={5}>
                            <Form.Item
                              name="investigation_of_male"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_male: value,
                                  });
                                }}
                                options={responseSet}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="investigation_of_male_date">
                              <DatePicker
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.investigation_of_male_date,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_male_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="investigation_of_male_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={
                                  patientInfo.investigation_of_male_location
                                }
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    investigation_of_male_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Semen Analysis</h3>
                        <Row>
                          <Col md={5}>
                            <Form.Item
                              name="semen_analysis"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.semen_analysis}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    semen_analysis: value,
                                  });
                                }}
                                options={responseSet}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="semen_analysis_date">
                              <DatePicker
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.semen_analysis_date,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    semen_analysis_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="semen_analysis_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.semen_analysis_location}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    semen_analysis_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Hysteroscopy</h3>
                        <Row>
                          <Col md={5}>
                            <Form.Item
                              name="hysteroscopy"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.hysteroscopy}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    hysteroscopy: value,
                                  });
                                }}
                                options={responseSet}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="hysteroscopy_date">
                              <DatePicker
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.hysteroscopy_date,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    hysteroscopy_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="hysteroscopy_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.hysteroscopy_location}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    hysteroscopy_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Type Of Treatment</h3>
                        <Row>
                          <Col md={6}>
                            <Form.Item
                              name="type_of_treatment"
                              className="custom_select"
                              label="Type Of Treatment"
                            >
                              <Select
                                placeholder="Select"
                                name="type_of_treatment"
                                value={patientInfo?.type_of_treatment}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    type_of_treatment: value,
                                    date_of_surgery: null,
                                    surgery_room_type: null,
                                    surgery_done_by: '',
                                    date_of_delivery: null,
                                    delivery_room_type: null,
                                    delivery_done_by: '',
                                    type_of_delivery: null,
                                    location_of_delivery: null,
                                    opu: null,
                                    opu_date: null,
                                    opu_location: null,
                                    outcome: null,
                                    outcome_date: null,
                                    outcome_location: null,
                                  });
                                  form.setFieldsValue({
                                    date_of_surgery: null,
                                    surgery_room_type: null,
                                    surgery_done_by: '',
                                    date_of_delivery: null,
                                    delivery_room_type: null,
                                    delivery_done_by: '',
                                    type_of_delivery: null,
                                    location_of_delivery: null,
                                    opu: null,
                                    opu_date: null,
                                    opu_location: null,
                                    outcome: null,
                                    outcome_date: null,
                                    outcome_location: null,
                                  });
                                }}
                                options={[
                                  { value: 'antenatal', label: 'Antenatal' },
                                  {
                                    value: 'lap surgery',
                                    label: 'Lap Surgery',
                                  },
                                  {
                                    value: 'IVF',
                                    label: 'IVF',
                                  },
                                  { value: 'gynec', label: 'Gynec' },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              label="Type Of Treatment Date"
                              name="type_of_treatment_date"
                            >
                              <DatePicker
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                value={dayjs(
                                  patientInfo?.type_of_treatment_date,
                                  dateFormat,
                                )}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    type_of_treatment_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              label="Type Of Treatment Location"
                              name="type_of_treatment_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.type_of_treatment_location}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    type_of_treatment_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6}></Col>
                          {patientInfo.type_of_treatment === 'antenatal' && (
                            <>
                              <Col md={6}>
                                <Form.Item
                                  label="Date Of Delivery"
                                  name="date_of_delivery"
                                  className="custom_select"
                                >
                                  <DatePicker
                                    format={{
                                      format: 'DD-MM-YYYY',
                                      type: 'mask',
                                    }}
                                    name="date_of_delivery"
                                    value={dayjs(
                                      patientInfo?.date_of_delivery,
                                      dateFormat,
                                    )}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        date_of_delivery: value
                                          ? moment(new Date(value)).format(
                                              dateFormat,
                                            )
                                          : null,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Room Type"
                                  name="delivery_room_type"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    name="delivery_room_type"
                                    value={patientInfo?.delivery_room_type}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        delivery_room_type: value,
                                      });
                                    }}
                                    options={[
                                      {
                                        value: 'general',
                                        label: 'General',
                                      },
                                      {
                                        value: 'twin sharing',
                                        label: 'Twin Sharing',
                                      },
                                      {
                                        value: 'deluxe',
                                        label: 'Deluxe',
                                      },
                                      {
                                        value: 'suite',
                                        label: 'Suite',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Done By"
                                  name="delivery_done_by"
                                  className="custom_select"
                                >
                                  <Input
                                    placeholder="Done by"
                                    name="delivery_done_by"
                                    value={patientInfo.delivery_done_by}
                                    onChange={e => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        delivery_done_by: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Type Of Delivery"
                                  name="type_of_delivery"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    name="type_of_delivery"
                                    value={patientInfo?.type_of_delivery}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        type_of_delivery: value,
                                      });
                                    }}
                                    options={[
                                      {
                                        value: 'normal ',
                                        label: 'Normal ',
                                      },
                                      {
                                        value: 'Cesarean ',
                                        label: 'Cesarean ',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Location"
                                  name="location_of_delivery"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    name="location_of_delivery"
                                    value={patientInfo.location_of_delivery}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        location_of_delivery: value,
                                      });
                                    }}
                                    options={locationField}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                          {patientInfo.type_of_treatment === 'lap surgery' && (
                            <>
                              <Col md={6}>
                                <Form.Item
                                  label="Date Of Surgery "
                                  name="date_of_surgery"
                                  className="custom_select"
                                >
                                  <DatePicker
                                    format={{
                                      format: 'DD-MM-YYYY',
                                      type: 'mask',
                                    }}
                                    name="date_of_surgery"
                                    value={dayjs(
                                      patientInfo?.date_of_surgery,
                                      dateFormat,
                                    )}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        date_of_surgery: value
                                          ? moment(new Date(value)).format(
                                              dateFormat,
                                            )
                                          : null,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Done By"
                                  name="surgery_done_by"
                                  className="custom_select"
                                >
                                  <Input
                                    placeholder="Done by"
                                    name="surgery_done_by"
                                    value={patientInfo.surgery_done_by}
                                    onChange={e => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        surgery_done_by: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={6}>
                                <Form.Item
                                  label="Type Of Room"
                                  name="surgery_room_type"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    name="surgery_room_type"
                                    value={patientInfo?.surgery_room_type}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        surgery_room_type: value,
                                      });
                                    }}
                                    options={[
                                      {
                                        value: 'general',
                                        label: 'General',
                                      },
                                      {
                                        value: 'twin sharing',
                                        label: 'Twin Sharing',
                                      },
                                      {
                                        value: 'deluxe',
                                        label: 'Deluxe',
                                      },
                                      {
                                        value: 'suite',
                                        label: 'Suite',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled">
                        <h3 className="mb-3">Stimulation Started</h3>
                        <Row>
                          <Col md={5}>
                            <Form.Item
                              name="stimulation_started"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.stimulation_started}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    stimulation_started: value,
                                  });
                                }}
                                options={responseSet}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="stimulation_started_date">
                              <DatePicker
                                value={dayjs(
                                  patientInfo?.stimulation_started_date,
                                  dateFormat,
                                )}
                                format={{
                                  format: 'DD-MM-YYYY',
                                  type: 'mask',
                                }}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    stimulation_started_date: value
                                      ? moment(new Date(value)).format(
                                          dateFormat,
                                        )
                                      : null,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="stimulation_started_location"
                              className="custom_select"
                            >
                              <Select
                                placeholder="Select"
                                value={patientInfo.stimulation_started_location}
                                onChange={value => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    stimulation_started_location: value,
                                  });
                                }}
                                options={locationField}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="form_info_wrapper filled pb24">
                        <h3 className="mb-3">Fees</h3>
                        <Row className="align-items-end">
                          <Col md={6} sm={10} xs={9}>
                            <Form.Item
                              label="Package"
                              rules={[
                                {
                                  pattern: /\d+/,
                                  message: '',
                                },
                              ]}
                              name="package_id"
                            >
                              <Input
                                placeholder="Enter Package"
                                name="package_id"
                                type="number"
                                value={patientInfo.package_id}
                                onChange={e => {
                                  setPatientInfo({
                                    ...patientInfo,
                                    package_id: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              className="btn_primary mb24 plus_btn min_auto"
                              onClick={handelfeesinput}
                            >
                              <PlusOutlined />
                            </Button>
                          </Col>
                        </Row>
                        <div className="bg_box_list_wrap">
                          {[...packData]?.map((item, index) => {
                            return (
                              <div
                                className="bgbox_wrap mb24"
                                key={`fees_package_${index}`}
                                name={`fees_package_div_${index}`}
                              >
                                <Row
                                  key={`fees_package_row_${index}`}
                                  name={`fees_package_row_${index}`}
                                >
                                  <Col sm={6}>
                                    <Form.Item
                                      label="Date"
                                      name={`package_date${index}`}
                                      key={`package_date${index}`}
                                    >
                                      <DatePicker
                                        value={dayjs(
                                          item?.package_date,
                                          dateFormat,
                                        )}
                                        format={{
                                          format: 'DD-MM-YYYY',
                                          type: 'mask',
                                        }}
                                        onChange={value => {
                                          onChangeFeesPackage(
                                            index,
                                            'package_date',
                                            value
                                              ? moment(new Date(value)).format(
                                                  dateFormat,
                                                )
                                              : null,
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Item
                                      label="Loaction"
                                      name={`package_location${index}`}
                                      className="custom_select"
                                      key={`fees_location${index}`}
                                    >
                                      <Select
                                        placeholder="Select Location"
                                        name={`package_location${index}`}
                                        value={item?.package_location}
                                        onChange={value => {
                                          onChangeFeesPackage(
                                            index,
                                            'package_location',
                                            value,
                                          );
                                        }}
                                        options={locationField}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Item
                                      label="Amount"
                                      name={`package_amount${index}`}
                                      key={`package_amount${index}`}
                                    >
                                      <Input
                                        placeholder="Amount"
                                        name="package_amount"
                                        type="number"
                                        value={item?.package_amount}
                                        onChange={e => {
                                          onChangeFeesPackage(
                                            index,
                                            'package_amount',
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Item
                                      rules={[
                                        {
                                          pattern: /\d+/,
                                          message: '',
                                        },
                                      ]}
                                      label="Receipt No"
                                      name={`receipt_no${index}`}
                                      key={`receipt_no${index}`}
                                    >
                                      <Input
                                        type="number"
                                        placeholder="Receipt No."
                                        value={item?.receipt_no}
                                        onChange={e => {
                                          onChangeFeesPackage(
                                            index,
                                            'receipt_no',
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                {/* <Button
                                  className="btn_transparent delete_btn"
                                  onClick={() => {
                                    onDeleteFeesPackage(index);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button> */}
                                <Popconfirm
                                  title="Delete Lead"
                                  description=" Are you sure you want to delete this fees details?"
                                  onConfirm={() => {
                                    // confirm(index);
                                    onDeleteFeesPackage(index);
                                  }}
                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    className="btn_transparent delete_btn"
                                    // onClick={() => {
                                    //   onDeleteFeesPackage(index);
                                    // }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {patientInfo.type_of_treatment === 'IVF' && (
                        <>
                          <div className="form_info_wrapper filled">
                            <h3 className="mb-3">OPU</h3>
                            <Row>
                              <Col md={5}>
                                <Form.Item name="opu" className="custom_select">
                                  <Select
                                    placeholder="Select"
                                    value={patientInfo.opu}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        opu: value,
                                      });
                                    }}
                                    options={responseSet}
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item name="opu_date">
                                  <DatePicker
                                    value={dayjs(
                                      patientInfo?.opu_date,
                                      dateFormat,
                                    )}
                                    format={{
                                      format: 'DD-MM-YYYY',
                                      type: 'mask',
                                    }}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        opu_date: value
                                          ? moment(new Date(value)).format(
                                              dateFormat,
                                            )
                                          : null,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="opu_location"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    value={patientInfo.opu_location}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        opu_location: value,
                                      });
                                    }}
                                    options={locationField}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <div className="form_info_wrapper filled">
                            <h3 className="mb-3">Outcome</h3>
                            <Row>
                              <Col md={5}>
                                <Form.Item
                                  name="outcome"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    value={patientInfo.outcome}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        outcome: value,
                                      });
                                    }}
                                    options={[
                                      { value: 'Positive', label: 'Positive' },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item name="outcome_date">
                                  <DatePicker
                                    value={dayjs(
                                      patientInfo?.outcome_date,
                                      dateFormat,
                                    )}
                                    format={{
                                      format: 'DD-MM-YYYY',
                                      type: 'mask',
                                    }}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        outcome_date: value
                                          ? moment(new Date(value)).format(
                                              dateFormat,
                                            )
                                          : null,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="outcome_location"
                                  className="custom_select"
                                >
                                  <Select
                                    placeholder="Select"
                                    value={patientInfo.outcome_location}
                                    onChange={value => {
                                      setPatientInfo({
                                        ...patientInfo,
                                        outcome_location: value,
                                      });
                                    }}
                                    options={locationField}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                      <div className="form_info_wrapper filled pb24">
                        <h3 className="mb-3">Status of Patient</h3>
                        <div className="round_radio_main_wrapper">
                          <div className="round_radio_wrapper">
                            <Radio.Group
                              name="customer_is_open"
                              value={patientInfo.customer_is_open}
                              defaultValue={1}
                              onChange={e =>
                                setPatientInfo({
                                  ...patientInfo,
                                  customer_is_open: e.target.value,
                                })
                              }
                            >
                              <Radio value={true}>Open</Radio>
                              <Radio value={false}>Close</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="button_group d-flex align-items-center justify-content-center mt-4">
                <Button className="btn_primary mx-3" htmlType="submit">
                  {state?.leadId ? 'Update' : 'Save'}
                </Button>
                <Button
                  className="btn_gray"
                  onClick={clearPatientBasicInformationForm}
                >
                  Reset
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLead;
