import React, { useEffect, useState } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { Col, Row } from 'react-bootstrap';
import TrashIcon from '../../../Img/trash.svg';
import EditIcon from '../../../Img/edit.svg';
import RestoreIcon from '../../../Img/restore.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  getUserListData,
  setIsUserCreated,
  updateUser,
} from 'redux/reducers/UserManagement/user.slice';
import { useCallback } from 'react';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useNavigate } from 'react-router';
import { getAuthToken } from 'Helper/AuthTokenHelper';

export default function AllUsers() {
  const navigate = useNavigate();
  const { userList, isUserCreated, userLoading, totolOfUser } = useSelector(
    ({ userData }) => userData,
  );
  let UserPreferences = getAuthToken();
  const dispatch = useDispatch();
  const [startPage, setStartPage] = useState(1);
  const [isDelete, setIsDelete] = useState(true);
  const handleDeleteToggle = useCallback(
    async item => {
      const payload = {
        user_id: item._id,
        country_code: item.country_code,
        user_name: item.user_name,
        user_mobile_no: item.user_mobile_no,
        delete: !item.deleted,
      };
      dispatch(updateUser(payload));
    },
    [dispatch],
  );
  useEffect(() => {
    dispatch(
      getUserListData({
        start: startPage,
        limit: 10,
      }),
    );
  }, [dispatch, startPage]);
  useEffect(() => {
    if (isUserCreated) {
      dispatch(
        getUserListData({
          start: startPage,
          limit: 10,
        }),
      );
      if (isDelete) navigate('/add-user');
      dispatch(setIsUserCreated(false));
    }
  }, [isUserCreated, dispatch, startPage]);

  const handlePageChange = useCallback(
    value => {
      setStartPage(value);
      dispatch(
        getUserListData({
          start: value,
          limit: 10,
        }),
      );
    },
    [dispatch],
  );
  return (
    <>
     <Helmet
           defaultTitle="Nimaaya Lead Management">
            <title>Nimaaya Lead Management - All Users</title>
            <meta name="description" content="A Nimaaya Lead Management application - All Users" />
        </Helmet>
      {userLoading && (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      )}
      <div className="page_main_content">
        <div className="page_inner_content">
          <div className="page_header">
            <Row className="align-items-center justify-content-between">
              <Col xs={9}>
                <h3>Users</h3>
              </Col>
              <Col xs={3}>
                <div className="text-end plus_mobile_wrap">
                  <Button
                    className="btn_primary"
                    onClick={() => {
                      navigate('/add-user');
                    }}
                  >
                    <PlusOutlined className="m-0 me-md-2" />
                    <span className="d-none d-md-inline-block">Add User</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="user_main_wrap">
            {userList?.map((item, index) => {
              return (
                <div className="user_box" key={item?._id}>
                  <Row className="align-items-center">
                    <Col xl={5} md={6} xs={11}>
                      <div className="d-flex align-items-center">
                        <div className="user_img">
                          {item?.profile_picture?.length > 0 ? (
                            <img src={item?.profile_picture} alt="" />
                          ) : (
                            <div className="user_name_letter">
                              {item?.user_name
                                ? `${item.user_name?.charAt(0)?.toUpperCase()}`
                                : 'UN'}
                            </div>
                          )}
                        </div>
                        <div className="user_text">
                          <h3 className="fw_400 mb-1">
                            <span className="pe-2">
                              {item?.user_name}
                              {UserPreferences?._id === item?._id
                                ? '   (You)'
                                : ''}
                            </span>
                            <span className="bedge bedge_active">Active</span>
                          </h3>
                          <p className="m-0">{item?.user_email}</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2} className="badge_wrap">
                      <h4 className="fw_400">
                        <span>{item?.location}</span>
                      </h4>
                    </Col>
                    <Col md={2} className="badge_wrap d-none d-md-block">
                      <span
                        className={
                          item?.deleted
                            ? 'bedge bedge_inactive'
                            : 'bedge bedge_active'
                        }
                      >
                        {item?.deleted ? 'In Active' : 'Active'}
                      </span>
                    </Col>
                    <Col xl={2} md={2}>
                      <ul className="d-flex align-items-center user_roll">
                        {item?.deleted ? (
                          <li className="btn_delete btn_edit">
                            <Button
                              className="btn_transparent"
                              onClick={() => {
                                setIsDelete(false);
                                handleDeleteToggle(item);
                              }}
                            >
                              <img
                                src={RestoreIcon}
                                alt="RestoreIcon"
                                className="me-2"
                              />
                            </Button>
                          </li>
                        ) : (
                          <>
                            <li className="btn_delete btn_edit">
                              <Button
                                className="btn_transparent"
                                onClick={e => {
                                  navigate('/edit-user', {
                                    state: { userId: item?._id },
                                  });
                                }}
                              >
                                <img src={EditIcon} alt="" />
                              </Button>
                            </li>
                            <li className="btn_delete">
                              <Button
                                className="btn_transparent"
                                onClick={e => {
                                  setIsDelete(false);
                                  handleDeleteToggle(item);
                                }}
                              >
                                <img src={TrashIcon} alt="" />
                              </Button>
                            </li>
                          </>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          <Pagination
            current={startPage}
            total={totolOfUser}
            pageSize={10}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}
